// source: score_standard.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var cutter_pb = require('./cutter_pb.js');
goog.object.extend(proto, cutter_pb);
var blank_pb = require('./blank_pb.js');
goog.object.extend(proto, blank_pb);
var major_pb = require('./major_pb.js');
goog.object.extend(proto, major_pb);
goog.exportSymbol('proto.pbs.BlankParam', null, global);
goog.exportSymbol('proto.pbs.ClampParam', null, global);
goog.exportSymbol('proto.pbs.CutterParam', null, global);
goog.exportSymbol('proto.pbs.Deviation', null, global);
goog.exportSymbol('proto.pbs.MachineDeviation', null, global);
goog.exportSymbol('proto.pbs.MachineEffectiveParam', null, global);
goog.exportSymbol('proto.pbs.MachineParam', null, global);
goog.exportSymbol('proto.pbs.MachineProcessParam', null, global);
goog.exportSymbol('proto.pbs.OperateLogParam', null, global);
goog.exportSymbol('proto.pbs.OperateLogParamDetail', null, global);
goog.exportSymbol('proto.pbs.OperateProcessParam', null, global);
goog.exportSymbol('proto.pbs.ProfileDetail', null, global);
goog.exportSymbol('proto.pbs.ProfileParam', null, global);
goog.exportSymbol('proto.pbs.ScoreStandard', null, global);
goog.exportSymbol('proto.pbs.ScoreStandardId', null, global);
goog.exportSymbol('proto.pbs.ScoreStandards', null, global);
goog.exportSymbol('proto.pbs.StandardListReq', null, global);
goog.exportSymbol('proto.pbs.WorkpieceParam', null, global);
goog.exportSymbol('proto.pbs.WorkpieceParamMilling', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.StandardListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.StandardListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.StandardListReq.displayName = 'proto.pbs.StandardListReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ScoreStandards = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ScoreStandards.repeatedFields_, null);
};
goog.inherits(proto.pbs.ScoreStandards, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ScoreStandards.displayName = 'proto.pbs.ScoreStandards';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ScoreStandardId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ScoreStandardId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ScoreStandardId.displayName = 'proto.pbs.ScoreStandardId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ClampParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ClampParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ClampParam.displayName = 'proto.pbs.ClampParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CutterParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.CutterParam.repeatedFields_, null);
};
goog.inherits(proto.pbs.CutterParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CutterParam.displayName = 'proto.pbs.CutterParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MachineParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MachineParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MachineParam.displayName = 'proto.pbs.MachineParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MachineDeviation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MachineDeviation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MachineDeviation.displayName = 'proto.pbs.MachineDeviation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MachineProcessParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.MachineProcessParam.repeatedFields_, null);
};
goog.inherits(proto.pbs.MachineProcessParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MachineProcessParam.displayName = 'proto.pbs.MachineProcessParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.WorkpieceParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.WorkpieceParam.repeatedFields_, null);
};
goog.inherits(proto.pbs.WorkpieceParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.WorkpieceParam.displayName = 'proto.pbs.WorkpieceParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Deviation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.Deviation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Deviation.displayName = 'proto.pbs.Deviation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.WorkpieceParamMilling = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.WorkpieceParamMilling.repeatedFields_, null);
};
goog.inherits(proto.pbs.WorkpieceParamMilling, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.WorkpieceParamMilling.displayName = 'proto.pbs.WorkpieceParamMilling';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MachineEffectiveParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MachineEffectiveParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MachineEffectiveParam.displayName = 'proto.pbs.MachineEffectiveParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.BlankParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.BlankParam.repeatedFields_, null);
};
goog.inherits(proto.pbs.BlankParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.BlankParam.displayName = 'proto.pbs.BlankParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.OperateProcessParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.OperateProcessParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.OperateProcessParam.displayName = 'proto.pbs.OperateProcessParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.OperateLogParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.OperateLogParam.repeatedFields_, null);
};
goog.inherits(proto.pbs.OperateLogParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.OperateLogParam.displayName = 'proto.pbs.OperateLogParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.OperateLogParamDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.OperateLogParamDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.OperateLogParamDetail.displayName = 'proto.pbs.OperateLogParamDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ProfileParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ProfileParam.repeatedFields_, null);
};
goog.inherits(proto.pbs.ProfileParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ProfileParam.displayName = 'proto.pbs.ProfileParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ProfileDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ProfileDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ProfileDetail.displayName = 'proto.pbs.ProfileDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ScoreStandard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ScoreStandard.repeatedFields_, null);
};
goog.inherits(proto.pbs.ScoreStandard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ScoreStandard.displayName = 'proto.pbs.ScoreStandard';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.StandardListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.StandardListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.StandardListReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StandardListReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    standardType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    modelType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    schoolId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    facultyId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    majorId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isBelongSchool: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.StandardListReq}
 */
proto.pbs.StandardListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.StandardListReq;
  return proto.pbs.StandardListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.StandardListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.StandardListReq}
 */
proto.pbs.StandardListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStandardType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setModelType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIsBelongSchool(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.StandardListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.StandardListReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.StandardListReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StandardListReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStandardType();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getModelType();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMajorId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsBelongSchool();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.pbs.StandardListReq.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StandardListReq} returns this
 */
proto.pbs.StandardListReq.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 page_size = 2;
 * @return {number}
 */
proto.pbs.StandardListReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StandardListReq} returns this
 */
proto.pbs.StandardListReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 standard_type = 3;
 * @return {number}
 */
proto.pbs.StandardListReq.prototype.getStandardType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StandardListReq} returns this
 */
proto.pbs.StandardListReq.prototype.setStandardType = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 model_type = 4;
 * @return {number}
 */
proto.pbs.StandardListReq.prototype.getModelType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StandardListReq} returns this
 */
proto.pbs.StandardListReq.prototype.setModelType = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string school_id = 5;
 * @return {string}
 */
proto.pbs.StandardListReq.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.StandardListReq} returns this
 */
proto.pbs.StandardListReq.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string faculty_id = 6;
 * @return {string}
 */
proto.pbs.StandardListReq.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.StandardListReq} returns this
 */
proto.pbs.StandardListReq.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string major_id = 7;
 * @return {string}
 */
proto.pbs.StandardListReq.prototype.getMajorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.StandardListReq} returns this
 */
proto.pbs.StandardListReq.prototype.setMajorId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 is_belong_school = 8;
 * @return {number}
 */
proto.pbs.StandardListReq.prototype.getIsBelongSchool = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StandardListReq} returns this
 */
proto.pbs.StandardListReq.prototype.setIsBelongSchool = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ScoreStandards.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ScoreStandards.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ScoreStandards.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ScoreStandards} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreStandards.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.pbs.ScoreStandard.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ScoreStandards}
 */
proto.pbs.ScoreStandards.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ScoreStandards;
  return proto.pbs.ScoreStandards.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ScoreStandards} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ScoreStandards}
 */
proto.pbs.ScoreStandards.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreStandard;
      reader.readMessage(value,proto.pbs.ScoreStandard.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ScoreStandards.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ScoreStandards.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ScoreStandards} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreStandards.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreStandard.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated ScoreStandard data = 1;
 * @return {!Array<!proto.pbs.ScoreStandard>}
 */
proto.pbs.ScoreStandards.prototype.getDataList = function() {
  return /** @type{!Array<!proto.pbs.ScoreStandard>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreStandard, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreStandard>} value
 * @return {!proto.pbs.ScoreStandards} returns this
*/
proto.pbs.ScoreStandards.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreStandard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreStandard}
 */
proto.pbs.ScoreStandards.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreStandard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreStandards} returns this
 */
proto.pbs.ScoreStandards.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.pbs.ScoreStandards.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreStandards} returns this
 */
proto.pbs.ScoreStandards.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ScoreStandardId.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ScoreStandardId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ScoreStandardId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreStandardId.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ScoreStandardId}
 */
proto.pbs.ScoreStandardId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ScoreStandardId;
  return proto.pbs.ScoreStandardId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ScoreStandardId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ScoreStandardId}
 */
proto.pbs.ScoreStandardId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ScoreStandardId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ScoreStandardId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ScoreStandardId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreStandardId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.pbs.ScoreStandardId.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreStandardId} returns this
 */
proto.pbs.ScoreStandardId.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ClampParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ClampParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ClampParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ClampParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    clampType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    positionMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    positionMax: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    clampScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    positionMinOther: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    positionMaxOther: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ClampParam}
 */
proto.pbs.ClampParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ClampParam;
  return proto.pbs.ClampParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ClampParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ClampParam}
 */
proto.pbs.ClampParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setClampType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPositionMin(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPositionMax(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClampScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPositionMinOther(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPositionMaxOther(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ClampParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ClampParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ClampParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ClampParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClampType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPositionMin();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getPositionMax();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getClampScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getPositionMinOther();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getPositionMaxOther();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional int32 clamp_type = 1;
 * @return {number}
 */
proto.pbs.ClampParam.prototype.getClampType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ClampParam} returns this
 */
proto.pbs.ClampParam.prototype.setClampType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double position_min = 2;
 * @return {number}
 */
proto.pbs.ClampParam.prototype.getPositionMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ClampParam} returns this
 */
proto.pbs.ClampParam.prototype.setPositionMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double position_max = 3;
 * @return {number}
 */
proto.pbs.ClampParam.prototype.getPositionMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ClampParam} returns this
 */
proto.pbs.ClampParam.prototype.setPositionMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double clamp_score = 4;
 * @return {number}
 */
proto.pbs.ClampParam.prototype.getClampScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ClampParam} returns this
 */
proto.pbs.ClampParam.prototype.setClampScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double position_min_other = 5;
 * @return {number}
 */
proto.pbs.ClampParam.prototype.getPositionMinOther = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ClampParam} returns this
 */
proto.pbs.ClampParam.prototype.setPositionMinOther = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double position_max_other = 6;
 * @return {number}
 */
proto.pbs.ClampParam.prototype.getPositionMaxOther = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ClampParam} returns this
 */
proto.pbs.ClampParam.prototype.setPositionMaxOther = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.CutterParam.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CutterParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CutterParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CutterParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CutterParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxCutterNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eachCutterScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    beyondCutterDeductScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    cuttersList: jspb.Message.toObjectList(msg.getCuttersList(),
    cutter_pb.Cutter.toObject, includeInstance),
    selectedCuttersList: jspb.Message.toObjectList(msg.getSelectedCuttersList(),
    cutter_pb.Cutter.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CutterParam}
 */
proto.pbs.CutterParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CutterParam;
  return proto.pbs.CutterParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CutterParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CutterParam}
 */
proto.pbs.CutterParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxCutterNum(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEachCutterScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBeyondCutterDeductScore(value);
      break;
    case 4:
      var value = new cutter_pb.Cutter;
      reader.readMessage(value,cutter_pb.Cutter.deserializeBinaryFromReader);
      msg.addCutters(value);
      break;
    case 5:
      var value = new cutter_pb.Cutter;
      reader.readMessage(value,cutter_pb.Cutter.deserializeBinaryFromReader);
      msg.addSelectedCutters(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CutterParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CutterParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CutterParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CutterParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxCutterNum();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEachCutterScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getBeyondCutterDeductScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCuttersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      cutter_pb.Cutter.serializeBinaryToWriter
    );
  }
  f = message.getSelectedCuttersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      cutter_pb.Cutter.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional int32 max_cutter_num = 1;
 * @return {number}
 */
proto.pbs.CutterParam.prototype.getMaxCutterNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CutterParam} returns this
 */
proto.pbs.CutterParam.prototype.setMaxCutterNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double each_cutter_score = 2;
 * @return {number}
 */
proto.pbs.CutterParam.prototype.getEachCutterScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CutterParam} returns this
 */
proto.pbs.CutterParam.prototype.setEachCutterScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double beyond_cutter_deduct_score = 3;
 * @return {number}
 */
proto.pbs.CutterParam.prototype.getBeyondCutterDeductScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CutterParam} returns this
 */
proto.pbs.CutterParam.prototype.setBeyondCutterDeductScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated Cutter cutters = 4;
 * @return {!Array<!proto.pbs.Cutter>}
 */
proto.pbs.CutterParam.prototype.getCuttersList = function() {
  return /** @type{!Array<!proto.pbs.Cutter>} */ (
    jspb.Message.getRepeatedWrapperField(this, cutter_pb.Cutter, 4));
};


/**
 * @param {!Array<!proto.pbs.Cutter>} value
 * @return {!proto.pbs.CutterParam} returns this
*/
proto.pbs.CutterParam.prototype.setCuttersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.pbs.Cutter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Cutter}
 */
proto.pbs.CutterParam.prototype.addCutters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.pbs.Cutter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CutterParam} returns this
 */
proto.pbs.CutterParam.prototype.clearCuttersList = function() {
  return this.setCuttersList([]);
};


/**
 * repeated Cutter selected_cutters = 5;
 * @return {!Array<!proto.pbs.Cutter>}
 */
proto.pbs.CutterParam.prototype.getSelectedCuttersList = function() {
  return /** @type{!Array<!proto.pbs.Cutter>} */ (
    jspb.Message.getRepeatedWrapperField(this, cutter_pb.Cutter, 5));
};


/**
 * @param {!Array<!proto.pbs.Cutter>} value
 * @return {!proto.pbs.CutterParam} returns this
*/
proto.pbs.CutterParam.prototype.setSelectedCuttersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.pbs.Cutter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Cutter}
 */
proto.pbs.CutterParam.prototype.addSelectedCutters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pbs.Cutter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CutterParam} returns this
 */
proto.pbs.CutterParam.prototype.clearSelectedCuttersList = function() {
  return this.setSelectedCuttersList([]);
};


/**
 * optional double total_score = 6;
 * @return {number}
 */
proto.pbs.CutterParam.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CutterParam} returns this
 */
proto.pbs.CutterParam.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MachineParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MachineParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MachineParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    craftType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    spindleSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    feedSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    backCutterMete: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    spindleSpeedAllowDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    feedSpeedAllowDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    backCutterMeteAllowDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    spindleSpeedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    feedSpeedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    backCutterMeteScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    cutter: (f = msg.getCutter()) && cutter_pb.Cutter.toObject(includeInstance, f),
    cutterScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    machineDeviation: (f = msg.getMachineDeviation()) && proto.pbs.MachineDeviation.toObject(includeInstance, f),
    remark: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MachineParam}
 */
proto.pbs.MachineParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MachineParam;
  return proto.pbs.MachineParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MachineParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MachineParam}
 */
proto.pbs.MachineParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCraftType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpindleSpeed(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeedSpeed(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackCutterMete(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpindleSpeedAllowDeviation(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeedSpeedAllowDeviation(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackCutterMeteAllowDeviation(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpindleSpeedScore(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeedSpeedScore(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackCutterMeteScore(value);
      break;
    case 11:
      var value = new cutter_pb.Cutter;
      reader.readMessage(value,cutter_pb.Cutter.deserializeBinaryFromReader);
      msg.setCutter(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCutterScore(value);
      break;
    case 13:
      var value = new proto.pbs.MachineDeviation;
      reader.readMessage(value,proto.pbs.MachineDeviation.deserializeBinaryFromReader);
      msg.setMachineDeviation(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MachineParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MachineParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MachineParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCraftType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSpindleSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getFeedSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getBackCutterMete();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getSpindleSpeedAllowDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getFeedSpeedAllowDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getBackCutterMeteAllowDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getSpindleSpeedScore();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getFeedSpeedScore();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getBackCutterMeteScore();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getCutter();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      cutter_pb.Cutter.serializeBinaryToWriter
    );
  }
  f = message.getCutterScore();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getMachineDeviation();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.pbs.MachineDeviation.serializeBinaryToWriter
    );
  }
  f = message.getRemark();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional int32 craft_type = 1;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getCraftType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setCraftType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double spindle_speed = 2;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getSpindleSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setSpindleSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double feed_speed = 3;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getFeedSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setFeedSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double back_cutter_mete = 4;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getBackCutterMete = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setBackCutterMete = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double spindle_speed_allow_deviation = 5;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getSpindleSpeedAllowDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setSpindleSpeedAllowDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double feed_speed_allow_deviation = 6;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getFeedSpeedAllowDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setFeedSpeedAllowDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double back_cutter_mete_allow_deviation = 7;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getBackCutterMeteAllowDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setBackCutterMeteAllowDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double spindle_speed_score = 8;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getSpindleSpeedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setSpindleSpeedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double feed_speed_score = 9;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getFeedSpeedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setFeedSpeedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double back_cutter_mete_score = 10;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getBackCutterMeteScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setBackCutterMeteScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional Cutter cutter = 11;
 * @return {?proto.pbs.Cutter}
 */
proto.pbs.MachineParam.prototype.getCutter = function() {
  return /** @type{?proto.pbs.Cutter} */ (
    jspb.Message.getWrapperField(this, cutter_pb.Cutter, 11));
};


/**
 * @param {?proto.pbs.Cutter|undefined} value
 * @return {!proto.pbs.MachineParam} returns this
*/
proto.pbs.MachineParam.prototype.setCutter = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.clearCutter = function() {
  return this.setCutter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MachineParam.prototype.hasCutter = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional double cutter_score = 12;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getCutterScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setCutterScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional MachineDeviation machine_deviation = 13;
 * @return {?proto.pbs.MachineDeviation}
 */
proto.pbs.MachineParam.prototype.getMachineDeviation = function() {
  return /** @type{?proto.pbs.MachineDeviation} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MachineDeviation, 13));
};


/**
 * @param {?proto.pbs.MachineDeviation|undefined} value
 * @return {!proto.pbs.MachineParam} returns this
*/
proto.pbs.MachineParam.prototype.setMachineDeviation = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.clearMachineDeviation = function() {
  return this.setMachineDeviation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MachineParam.prototype.hasMachineDeviation = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string remark = 14;
 * @return {string}
 */
proto.pbs.MachineParam.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setRemark = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MachineDeviation.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MachineDeviation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MachineDeviation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineDeviation.toObject = function(includeInstance, msg) {
  var f, obj = {
    spindleSpeedDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    feedSpeedDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    backCutterMeteDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    spindleSpeedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    feedSpeedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    backCutterMeteScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    backupCutter: (f = msg.getBackupCutter()) && cutter_pb.Cutter.toObject(includeInstance, f),
    backupCutterScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MachineDeviation}
 */
proto.pbs.MachineDeviation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MachineDeviation;
  return proto.pbs.MachineDeviation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MachineDeviation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MachineDeviation}
 */
proto.pbs.MachineDeviation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpindleSpeedDeviation(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeedSpeedDeviation(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackCutterMeteDeviation(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpindleSpeedScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeedSpeedScore(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackCutterMeteScore(value);
      break;
    case 7:
      var value = new cutter_pb.Cutter;
      reader.readMessage(value,cutter_pb.Cutter.deserializeBinaryFromReader);
      msg.setBackupCutter(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackupCutterScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MachineDeviation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MachineDeviation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MachineDeviation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineDeviation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpindleSpeedDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getFeedSpeedDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getBackCutterMeteDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getSpindleSpeedScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getFeedSpeedScore();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getBackCutterMeteScore();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getBackupCutter();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      cutter_pb.Cutter.serializeBinaryToWriter
    );
  }
  f = message.getBackupCutterScore();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional double spindle_speed_deviation = 1;
 * @return {number}
 */
proto.pbs.MachineDeviation.prototype.getSpindleSpeedDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineDeviation} returns this
 */
proto.pbs.MachineDeviation.prototype.setSpindleSpeedDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double feed_speed_deviation = 2;
 * @return {number}
 */
proto.pbs.MachineDeviation.prototype.getFeedSpeedDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineDeviation} returns this
 */
proto.pbs.MachineDeviation.prototype.setFeedSpeedDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double back_cutter_mete_deviation = 3;
 * @return {number}
 */
proto.pbs.MachineDeviation.prototype.getBackCutterMeteDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineDeviation} returns this
 */
proto.pbs.MachineDeviation.prototype.setBackCutterMeteDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double spindle_speed_score = 4;
 * @return {number}
 */
proto.pbs.MachineDeviation.prototype.getSpindleSpeedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineDeviation} returns this
 */
proto.pbs.MachineDeviation.prototype.setSpindleSpeedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double feed_speed_score = 5;
 * @return {number}
 */
proto.pbs.MachineDeviation.prototype.getFeedSpeedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineDeviation} returns this
 */
proto.pbs.MachineDeviation.prototype.setFeedSpeedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double back_cutter_mete_score = 6;
 * @return {number}
 */
proto.pbs.MachineDeviation.prototype.getBackCutterMeteScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineDeviation} returns this
 */
proto.pbs.MachineDeviation.prototype.setBackCutterMeteScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional Cutter backup_cutter = 7;
 * @return {?proto.pbs.Cutter}
 */
proto.pbs.MachineDeviation.prototype.getBackupCutter = function() {
  return /** @type{?proto.pbs.Cutter} */ (
    jspb.Message.getWrapperField(this, cutter_pb.Cutter, 7));
};


/**
 * @param {?proto.pbs.Cutter|undefined} value
 * @return {!proto.pbs.MachineDeviation} returns this
*/
proto.pbs.MachineDeviation.prototype.setBackupCutter = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MachineDeviation} returns this
 */
proto.pbs.MachineDeviation.prototype.clearBackupCutter = function() {
  return this.setBackupCutter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MachineDeviation.prototype.hasBackupCutter = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional double backup_cutter_score = 8;
 * @return {number}
 */
proto.pbs.MachineDeviation.prototype.getBackupCutterScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineDeviation} returns this
 */
proto.pbs.MachineDeviation.prototype.setBackupCutterScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.MachineProcessParam.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MachineProcessParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MachineProcessParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MachineProcessParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineProcessParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    dependProcessList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    processScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MachineProcessParam}
 */
proto.pbs.MachineProcessParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MachineProcessParam;
  return proto.pbs.MachineProcessParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MachineProcessParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MachineProcessParam}
 */
proto.pbs.MachineProcessParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setDependProcessList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProcessScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MachineProcessParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MachineProcessParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MachineProcessParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineProcessParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDependProcessList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getProcessScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * repeated int32 depend_process = 1;
 * @return {!Array<number>}
 */
proto.pbs.MachineProcessParam.prototype.getDependProcessList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.pbs.MachineProcessParam} returns this
 */
proto.pbs.MachineProcessParam.prototype.setDependProcessList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.pbs.MachineProcessParam} returns this
 */
proto.pbs.MachineProcessParam.prototype.addDependProcess = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MachineProcessParam} returns this
 */
proto.pbs.MachineProcessParam.prototype.clearDependProcessList = function() {
  return this.setDependProcessList([]);
};


/**
 * optional double process_score = 2;
 * @return {number}
 */
proto.pbs.MachineProcessParam.prototype.getProcessScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineProcessParam} returns this
 */
proto.pbs.MachineProcessParam.prototype.setProcessScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.WorkpieceParam.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.WorkpieceParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.WorkpieceParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.WorkpieceParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.WorkpieceParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    pieceType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nominalSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    angle: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    roughness: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    midDiameter: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    upperDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    underDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    deviationList: jspb.Message.toObjectList(msg.getDeviationList(),
    proto.pbs.Deviation.toObject, includeInstance),
    remark: jspb.Message.getFieldWithDefault(msg, 10, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    tolerance: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    toleranceType: jspb.Message.getFieldWithDefault(msg, 17, 0),
    workpieceIndex: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.WorkpieceParam}
 */
proto.pbs.WorkpieceParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.WorkpieceParam;
  return proto.pbs.WorkpieceParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.WorkpieceParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.WorkpieceParam}
 */
proto.pbs.WorkpieceParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPieceType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNominalSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAngle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRoughness(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMidDiameter(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUpperDeviation(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUnderDeviation(value);
      break;
    case 9:
      var value = new proto.pbs.Deviation;
      reader.readMessage(value,proto.pbs.Deviation.deserializeBinaryFromReader);
      msg.addDeviation(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTolerance(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setToleranceType(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkpieceIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.WorkpieceParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.WorkpieceParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.WorkpieceParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.WorkpieceParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPieceType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNominalSize();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAngle();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getRoughness();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getMidDiameter();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getUpperDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getUnderDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getDeviationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.pbs.Deviation.serializeBinaryToWriter
    );
  }
  f = message.getRemark();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getTolerance();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getToleranceType();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getWorkpieceIndex();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
};


/**
 * optional int32 piece_type = 1;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getPieceType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setPieceType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double nominal_size = 2;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getNominalSize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setNominalSize = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double angle = 3;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double roughness = 4;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getRoughness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setRoughness = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double radius = 5;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double mid_diameter = 6;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getMidDiameter = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setMidDiameter = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double upper_deviation = 7;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getUpperDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setUpperDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double under_deviation = 8;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getUnderDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setUnderDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * repeated Deviation deviation = 9;
 * @return {!Array<!proto.pbs.Deviation>}
 */
proto.pbs.WorkpieceParam.prototype.getDeviationList = function() {
  return /** @type{!Array<!proto.pbs.Deviation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Deviation, 9));
};


/**
 * @param {!Array<!proto.pbs.Deviation>} value
 * @return {!proto.pbs.WorkpieceParam} returns this
*/
proto.pbs.WorkpieceParam.prototype.setDeviationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.pbs.Deviation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Deviation}
 */
proto.pbs.WorkpieceParam.prototype.addDeviation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.pbs.Deviation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.clearDeviationList = function() {
  return this.setDeviationList([]);
};


/**
 * optional string remark = 10;
 * @return {string}
 */
proto.pbs.WorkpieceParam.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setRemark = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional double score = 11;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double tolerance = 16;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getTolerance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setTolerance = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional int32 tolerance_type = 17;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getToleranceType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setToleranceType = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 workpiece_index = 18;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getWorkpieceIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setWorkpieceIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Deviation.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Deviation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Deviation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Deviation.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Deviation}
 */
proto.pbs.Deviation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Deviation;
  return proto.pbs.Deviation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Deviation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Deviation}
 */
proto.pbs.Deviation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeviation(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Deviation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Deviation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Deviation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Deviation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double deviation = 1;
 * @return {number}
 */
proto.pbs.Deviation.prototype.getDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Deviation} returns this
 */
proto.pbs.Deviation.prototype.setDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double score = 2;
 * @return {number}
 */
proto.pbs.Deviation.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Deviation} returns this
 */
proto.pbs.Deviation.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.WorkpieceParamMilling.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.WorkpieceParamMilling.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.WorkpieceParamMilling.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.WorkpieceParamMilling} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.WorkpieceParamMilling.toObject = function(includeInstance, msg) {
  var f, obj = {
    pieceType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    workpieceIndex: jspb.Message.getFieldWithDefault(msg, 13, 0),
    param: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    upperDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    underDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    deviationList: jspb.Message.toObjectList(msg.getDeviationList(),
    proto.pbs.Deviation.toObject, includeInstance),
    remark: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.WorkpieceParamMilling}
 */
proto.pbs.WorkpieceParamMilling.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.WorkpieceParamMilling;
  return proto.pbs.WorkpieceParamMilling.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.WorkpieceParamMilling} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.WorkpieceParamMilling}
 */
proto.pbs.WorkpieceParamMilling.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPieceType(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkpieceIndex(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setParam(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUpperDeviation(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUnderDeviation(value);
      break;
    case 9:
      var value = new proto.pbs.Deviation;
      reader.readMessage(value,proto.pbs.Deviation.deserializeBinaryFromReader);
      msg.addDeviation(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.WorkpieceParamMilling.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.WorkpieceParamMilling.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.WorkpieceParamMilling} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.WorkpieceParamMilling.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPieceType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getWorkpieceIndex();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getParam();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getUpperDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getUnderDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getDeviationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.pbs.Deviation.serializeBinaryToWriter
    );
  }
  f = message.getRemark();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional int32 piece_type = 1;
 * @return {number}
 */
proto.pbs.WorkpieceParamMilling.prototype.getPieceType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
 */
proto.pbs.WorkpieceParamMilling.prototype.setPieceType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double score = 12;
 * @return {number}
 */
proto.pbs.WorkpieceParamMilling.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
 */
proto.pbs.WorkpieceParamMilling.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional int32 workpiece_index = 13;
 * @return {number}
 */
proto.pbs.WorkpieceParamMilling.prototype.getWorkpieceIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
 */
proto.pbs.WorkpieceParamMilling.prototype.setWorkpieceIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional double param = 14;
 * @return {number}
 */
proto.pbs.WorkpieceParamMilling.prototype.getParam = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
 */
proto.pbs.WorkpieceParamMilling.prototype.setParam = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double upper_deviation = 7;
 * @return {number}
 */
proto.pbs.WorkpieceParamMilling.prototype.getUpperDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
 */
proto.pbs.WorkpieceParamMilling.prototype.setUpperDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double under_deviation = 8;
 * @return {number}
 */
proto.pbs.WorkpieceParamMilling.prototype.getUnderDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
 */
proto.pbs.WorkpieceParamMilling.prototype.setUnderDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * repeated Deviation deviation = 9;
 * @return {!Array<!proto.pbs.Deviation>}
 */
proto.pbs.WorkpieceParamMilling.prototype.getDeviationList = function() {
  return /** @type{!Array<!proto.pbs.Deviation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Deviation, 9));
};


/**
 * @param {!Array<!proto.pbs.Deviation>} value
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
*/
proto.pbs.WorkpieceParamMilling.prototype.setDeviationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.pbs.Deviation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Deviation}
 */
proto.pbs.WorkpieceParamMilling.prototype.addDeviation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.pbs.Deviation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
 */
proto.pbs.WorkpieceParamMilling.prototype.clearDeviationList = function() {
  return this.setDeviationList([]);
};


/**
 * optional string remark = 10;
 * @return {string}
 */
proto.pbs.WorkpieceParamMilling.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
 */
proto.pbs.WorkpieceParamMilling.prototype.setRemark = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MachineEffectiveParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MachineEffectiveParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MachineEffectiveParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineEffectiveParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    ncRunTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ncScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    installCutterNum: jspb.Message.getFieldWithDefault(msg, 3, 0),
    installCutterScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    changeBlankNum: jspb.Message.getFieldWithDefault(msg, 5, 0),
    changeBlankScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    changeBlankDeductScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    hitCutterNum: jspb.Message.getFieldWithDefault(msg, 8, 0),
    hitCutterScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    hitCutterDeductScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    workpieceQualityProp: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    workpieceQualityScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    installCutterDeductScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MachineEffectiveParam}
 */
proto.pbs.MachineEffectiveParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MachineEffectiveParam;
  return proto.pbs.MachineEffectiveParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MachineEffectiveParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MachineEffectiveParam}
 */
proto.pbs.MachineEffectiveParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNcRunTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNcScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInstallCutterNum(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInstallCutterScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChangeBlankNum(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangeBlankScore(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangeBlankDeductScore(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHitCutterNum(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHitCutterScore(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHitCutterDeductScore(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWorkpieceQualityProp(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWorkpieceQualityScore(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInstallCutterDeductScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MachineEffectiveParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MachineEffectiveParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MachineEffectiveParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineEffectiveParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNcRunTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNcScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getInstallCutterNum();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getInstallCutterScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getChangeBlankNum();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getChangeBlankScore();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getChangeBlankDeductScore();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getHitCutterNum();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getHitCutterScore();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getHitCutterDeductScore();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getWorkpieceQualityProp();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getWorkpieceQualityScore();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getInstallCutterDeductScore();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
};


/**
 * optional int64 nc_run_time = 1;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getNcRunTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setNcRunTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double nc_score = 2;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getNcScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setNcScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int64 install_cutter_num = 3;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getInstallCutterNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setInstallCutterNum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double install_cutter_score = 4;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getInstallCutterScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setInstallCutterScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int64 change_blank_num = 5;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getChangeBlankNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setChangeBlankNum = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double change_blank_score = 6;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getChangeBlankScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setChangeBlankScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double change_blank_deduct_score = 7;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getChangeBlankDeductScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setChangeBlankDeductScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int64 hit_cutter_num = 8;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getHitCutterNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setHitCutterNum = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional double hit_cutter_score = 9;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getHitCutterScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setHitCutterScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double hit_cutter_deduct_score = 10;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getHitCutterDeductScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setHitCutterDeductScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double workpiece_quality_prop = 11;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getWorkpieceQualityProp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setWorkpieceQualityProp = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double workpiece_quality_score = 12;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getWorkpieceQualityScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setWorkpieceQualityScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double install_cutter_deduct_score = 13;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getInstallCutterDeductScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setInstallCutterDeductScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.BlankParam.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.BlankParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.BlankParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.BlankParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.BlankParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    correctBlank: (f = msg.getCorrectBlank()) && blank_pb.Blank.toObject(includeInstance, f),
    halfCorrectBlank: (f = msg.getHalfCorrectBlank()) && blank_pb.Blank.toObject(includeInstance, f),
    correctBlankScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    halfCorrectBlankScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    selectedBlanksList: jspb.Message.toObjectList(msg.getSelectedBlanksList(),
    blank_pb.Blank.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.BlankParam}
 */
proto.pbs.BlankParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.BlankParam;
  return proto.pbs.BlankParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.BlankParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.BlankParam}
 */
proto.pbs.BlankParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new blank_pb.Blank;
      reader.readMessage(value,blank_pb.Blank.deserializeBinaryFromReader);
      msg.setCorrectBlank(value);
      break;
    case 2:
      var value = new blank_pb.Blank;
      reader.readMessage(value,blank_pb.Blank.deserializeBinaryFromReader);
      msg.setHalfCorrectBlank(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCorrectBlankScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHalfCorrectBlankScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 6:
      var value = new blank_pb.Blank;
      reader.readMessage(value,blank_pb.Blank.deserializeBinaryFromReader);
      msg.addSelectedBlanks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.BlankParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.BlankParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.BlankParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.BlankParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrectBlank();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      blank_pb.Blank.serializeBinaryToWriter
    );
  }
  f = message.getHalfCorrectBlank();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      blank_pb.Blank.serializeBinaryToWriter
    );
  }
  f = message.getCorrectBlankScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getHalfCorrectBlankScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getSelectedBlanksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      blank_pb.Blank.serializeBinaryToWriter
    );
  }
};


/**
 * optional Blank correct_blank = 1;
 * @return {?proto.pbs.Blank}
 */
proto.pbs.BlankParam.prototype.getCorrectBlank = function() {
  return /** @type{?proto.pbs.Blank} */ (
    jspb.Message.getWrapperField(this, blank_pb.Blank, 1));
};


/**
 * @param {?proto.pbs.Blank|undefined} value
 * @return {!proto.pbs.BlankParam} returns this
*/
proto.pbs.BlankParam.prototype.setCorrectBlank = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.BlankParam} returns this
 */
proto.pbs.BlankParam.prototype.clearCorrectBlank = function() {
  return this.setCorrectBlank(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.BlankParam.prototype.hasCorrectBlank = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Blank half_correct_blank = 2;
 * @return {?proto.pbs.Blank}
 */
proto.pbs.BlankParam.prototype.getHalfCorrectBlank = function() {
  return /** @type{?proto.pbs.Blank} */ (
    jspb.Message.getWrapperField(this, blank_pb.Blank, 2));
};


/**
 * @param {?proto.pbs.Blank|undefined} value
 * @return {!proto.pbs.BlankParam} returns this
*/
proto.pbs.BlankParam.prototype.setHalfCorrectBlank = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.BlankParam} returns this
 */
proto.pbs.BlankParam.prototype.clearHalfCorrectBlank = function() {
  return this.setHalfCorrectBlank(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.BlankParam.prototype.hasHalfCorrectBlank = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double correct_blank_score = 3;
 * @return {number}
 */
proto.pbs.BlankParam.prototype.getCorrectBlankScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.BlankParam} returns this
 */
proto.pbs.BlankParam.prototype.setCorrectBlankScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double half_correct_blank_score = 4;
 * @return {number}
 */
proto.pbs.BlankParam.prototype.getHalfCorrectBlankScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.BlankParam} returns this
 */
proto.pbs.BlankParam.prototype.setHalfCorrectBlankScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double total_score = 5;
 * @return {number}
 */
proto.pbs.BlankParam.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.BlankParam} returns this
 */
proto.pbs.BlankParam.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated Blank selected_blanks = 6;
 * @return {!Array<!proto.pbs.Blank>}
 */
proto.pbs.BlankParam.prototype.getSelectedBlanksList = function() {
  return /** @type{!Array<!proto.pbs.Blank>} */ (
    jspb.Message.getRepeatedWrapperField(this, blank_pb.Blank, 6));
};


/**
 * @param {!Array<!proto.pbs.Blank>} value
 * @return {!proto.pbs.BlankParam} returns this
*/
proto.pbs.BlankParam.prototype.setSelectedBlanksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.pbs.Blank=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Blank}
 */
proto.pbs.BlankParam.prototype.addSelectedBlanks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.pbs.Blank, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.BlankParam} returns this
 */
proto.pbs.BlankParam.prototype.clearSelectedBlanksList = function() {
  return this.setSelectedBlanksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.OperateProcessParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.OperateProcessParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.OperateProcessParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateProcessParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    operateType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.OperateProcessParam}
 */
proto.pbs.OperateProcessParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.OperateProcessParam;
  return proto.pbs.OperateProcessParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.OperateProcessParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.OperateProcessParam}
 */
proto.pbs.OperateProcessParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOperateType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.OperateProcessParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.OperateProcessParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.OperateProcessParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateProcessParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperateType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int32 operate_type = 1;
 * @return {number}
 */
proto.pbs.OperateProcessParam.prototype.getOperateType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateProcessParam} returns this
 */
proto.pbs.OperateProcessParam.prototype.setOperateType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double score = 2;
 * @return {number}
 */
proto.pbs.OperateProcessParam.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateProcessParam} returns this
 */
proto.pbs.OperateProcessParam.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.OperateLogParam.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.OperateLogParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.OperateLogParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.OperateLogParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateLogParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    operateLogParamList: jspb.Message.toObjectList(msg.getOperateLogParamList(),
    proto.pbs.OperateLogParamDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.OperateLogParam}
 */
proto.pbs.OperateLogParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.OperateLogParam;
  return proto.pbs.OperateLogParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.OperateLogParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.OperateLogParam}
 */
proto.pbs.OperateLogParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 2:
      var value = new proto.pbs.OperateLogParamDetail;
      reader.readMessage(value,proto.pbs.OperateLogParamDetail.deserializeBinaryFromReader);
      msg.addOperateLogParam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.OperateLogParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.OperateLogParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.OperateLogParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateLogParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getOperateLogParamList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.OperateLogParamDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional double total_score = 1;
 * @return {number}
 */
proto.pbs.OperateLogParam.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateLogParam} returns this
 */
proto.pbs.OperateLogParam.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * repeated OperateLogParamDetail operate_log_param = 2;
 * @return {!Array<!proto.pbs.OperateLogParamDetail>}
 */
proto.pbs.OperateLogParam.prototype.getOperateLogParamList = function() {
  return /** @type{!Array<!proto.pbs.OperateLogParamDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.OperateLogParamDetail, 2));
};


/**
 * @param {!Array<!proto.pbs.OperateLogParamDetail>} value
 * @return {!proto.pbs.OperateLogParam} returns this
*/
proto.pbs.OperateLogParam.prototype.setOperateLogParamList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.OperateLogParamDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.OperateLogParamDetail}
 */
proto.pbs.OperateLogParam.prototype.addOperateLogParam = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.OperateLogParamDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.OperateLogParam} returns this
 */
proto.pbs.OperateLogParam.prototype.clearOperateLogParamList = function() {
  return this.setOperateLogParamList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.OperateLogParamDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.OperateLogParamDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.OperateLogParamDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateLogParamDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    operateType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    deductScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    deductType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deductMaxNum: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.OperateLogParamDetail}
 */
proto.pbs.OperateLogParamDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.OperateLogParamDetail;
  return proto.pbs.OperateLogParamDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.OperateLogParamDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.OperateLogParamDetail}
 */
proto.pbs.OperateLogParamDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOperateType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeductScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeductType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeductMaxNum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.OperateLogParamDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.OperateLogParamDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.OperateLogParamDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateLogParamDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperateType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDeductScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getDeductType();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDeductMaxNum();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 operate_type = 1;
 * @return {number}
 */
proto.pbs.OperateLogParamDetail.prototype.getOperateType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateLogParamDetail} returns this
 */
proto.pbs.OperateLogParamDetail.prototype.setOperateType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double deduct_score = 2;
 * @return {number}
 */
proto.pbs.OperateLogParamDetail.prototype.getDeductScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateLogParamDetail} returns this
 */
proto.pbs.OperateLogParamDetail.prototype.setDeductScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 deduct_type = 3;
 * @return {number}
 */
proto.pbs.OperateLogParamDetail.prototype.getDeductType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateLogParamDetail} returns this
 */
proto.pbs.OperateLogParamDetail.prototype.setDeductType = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 deduct_max_num = 4;
 * @return {number}
 */
proto.pbs.OperateLogParamDetail.prototype.getDeductMaxNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateLogParamDetail} returns this
 */
proto.pbs.OperateLogParamDetail.prototype.setDeductMaxNum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ProfileParam.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ProfileParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ProfileParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ProfileParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ProfileParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileName: jspb.Message.getFieldWithDefault(msg, 1, 0),
    profileListList: jspb.Message.toObjectList(msg.getProfileListList(),
    proto.pbs.ProfileDetail.toObject, includeInstance),
    profileImg: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ProfileParam}
 */
proto.pbs.ProfileParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ProfileParam;
  return proto.pbs.ProfileParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ProfileParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ProfileParam}
 */
proto.pbs.ProfileParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProfileName(value);
      break;
    case 2:
      var value = new proto.pbs.ProfileDetail;
      reader.readMessage(value,proto.pbs.ProfileDetail.deserializeBinaryFromReader);
      msg.addProfileList(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileImg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ProfileParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ProfileParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ProfileParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ProfileParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileName();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProfileListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.ProfileDetail.serializeBinaryToWriter
    );
  }
  f = message.getProfileImg();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 profile_name = 1;
 * @return {number}
 */
proto.pbs.ProfileParam.prototype.getProfileName = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfileParam} returns this
 */
proto.pbs.ProfileParam.prototype.setProfileName = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated ProfileDetail profile_list = 2;
 * @return {!Array<!proto.pbs.ProfileDetail>}
 */
proto.pbs.ProfileParam.prototype.getProfileListList = function() {
  return /** @type{!Array<!proto.pbs.ProfileDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ProfileDetail, 2));
};


/**
 * @param {!Array<!proto.pbs.ProfileDetail>} value
 * @return {!proto.pbs.ProfileParam} returns this
*/
proto.pbs.ProfileParam.prototype.setProfileListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.ProfileDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ProfileDetail}
 */
proto.pbs.ProfileParam.prototype.addProfileList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.ProfileDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ProfileParam} returns this
 */
proto.pbs.ProfileParam.prototype.clearProfileListList = function() {
  return this.setProfileListList([]);
};


/**
 * optional string profile_img = 3;
 * @return {string}
 */
proto.pbs.ProfileParam.prototype.getProfileImg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ProfileParam} returns this
 */
proto.pbs.ProfileParam.prototype.setProfileImg = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ProfileDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ProfileDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ProfileDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ProfileDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startPointX: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    startPointZ: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    endPointX: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    endPointZ: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    arcPointX: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    arcPointZ: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ProfileDetail}
 */
proto.pbs.ProfileDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ProfileDetail;
  return proto.pbs.ProfileDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ProfileDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ProfileDetail}
 */
proto.pbs.ProfileDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProfileType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStartPointX(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStartPointZ(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEndPointX(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEndPointZ(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setArcPointX(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setArcPointZ(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ProfileDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ProfileDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ProfileDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ProfileDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStartPointX();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getStartPointZ();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getEndPointX();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getEndPointZ();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getArcPointX();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getArcPointZ();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional int32 profile_type = 1;
 * @return {number}
 */
proto.pbs.ProfileDetail.prototype.getProfileType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfileDetail} returns this
 */
proto.pbs.ProfileDetail.prototype.setProfileType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double start_point_x = 2;
 * @return {number}
 */
proto.pbs.ProfileDetail.prototype.getStartPointX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfileDetail} returns this
 */
proto.pbs.ProfileDetail.prototype.setStartPointX = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double start_point_z = 3;
 * @return {number}
 */
proto.pbs.ProfileDetail.prototype.getStartPointZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfileDetail} returns this
 */
proto.pbs.ProfileDetail.prototype.setStartPointZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double end_point_x = 4;
 * @return {number}
 */
proto.pbs.ProfileDetail.prototype.getEndPointX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfileDetail} returns this
 */
proto.pbs.ProfileDetail.prototype.setEndPointX = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double end_point_z = 5;
 * @return {number}
 */
proto.pbs.ProfileDetail.prototype.getEndPointZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfileDetail} returns this
 */
proto.pbs.ProfileDetail.prototype.setEndPointZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double radius = 6;
 * @return {number}
 */
proto.pbs.ProfileDetail.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfileDetail} returns this
 */
proto.pbs.ProfileDetail.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double arc_point_x = 7;
 * @return {number}
 */
proto.pbs.ProfileDetail.prototype.getArcPointX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfileDetail} returns this
 */
proto.pbs.ProfileDetail.prototype.setArcPointX = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double arc_point_z = 8;
 * @return {number}
 */
proto.pbs.ProfileDetail.prototype.getArcPointZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfileDetail} returns this
 */
proto.pbs.ProfileDetail.prototype.setArcPointZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ScoreStandard.repeatedFields_ = [4,6,7,9,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ScoreStandard.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ScoreStandard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ScoreStandard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreStandard.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    blank: (f = msg.getBlank()) && proto.pbs.BlankParam.toObject(includeInstance, f),
    clampParamList: jspb.Message.toObjectList(msg.getClampParamList(),
    proto.pbs.ClampParam.toObject, includeInstance),
    cutter: (f = msg.getCutter()) && proto.pbs.CutterParam.toObject(includeInstance, f),
    operateProcessList: jspb.Message.toObjectList(msg.getOperateProcessList(),
    proto.pbs.OperateProcessParam.toObject, includeInstance),
    machineList: jspb.Message.toObjectList(msg.getMachineList(),
    proto.pbs.MachineParam.toObject, includeInstance),
    machineProcessMap: (f = msg.getMachineProcessMap()) ? f.toObject(includeInstance, proto.pbs.MachineProcessParam.toObject) : [],
    workpieceList: jspb.Message.toObjectList(msg.getWorkpieceList(),
    proto.pbs.WorkpieceParam.toObject, includeInstance),
    machineEffective: (f = msg.getMachineEffective()) && proto.pbs.MachineEffectiveParam.toObject(includeInstance, f),
    operateLogParam: (f = msg.getOperateLogParam()) && proto.pbs.OperateLogParam.toObject(includeInstance, f),
    profileParam: (f = msg.getProfileParam()) && proto.pbs.ProfileParam.toObject(includeInstance, f),
    standardType: jspb.Message.getFieldWithDefault(msg, 13, 0),
    workpieceMillingList: jspb.Message.toObjectList(msg.getWorkpieceMillingList(),
    proto.pbs.WorkpieceParamMilling.toObject, includeInstance),
    modelType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 15, 0),
    workpieceImg: jspb.Message.getFieldWithDefault(msg, 17, ""),
    schoolId: jspb.Message.getFieldWithDefault(msg, 18, ""),
    facultyId: jspb.Message.getFieldWithDefault(msg, 19, ""),
    major: (f = msg.getMajor()) && major_pb.Major.toObject(includeInstance, f),
    deletedAt: jspb.Message.getFieldWithDefault(msg, 21, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ScoreStandard}
 */
proto.pbs.ScoreStandard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ScoreStandard;
  return proto.pbs.ScoreStandard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ScoreStandard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ScoreStandard}
 */
proto.pbs.ScoreStandard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.pbs.BlankParam;
      reader.readMessage(value,proto.pbs.BlankParam.deserializeBinaryFromReader);
      msg.setBlank(value);
      break;
    case 4:
      var value = new proto.pbs.ClampParam;
      reader.readMessage(value,proto.pbs.ClampParam.deserializeBinaryFromReader);
      msg.addClampParam(value);
      break;
    case 5:
      var value = new proto.pbs.CutterParam;
      reader.readMessage(value,proto.pbs.CutterParam.deserializeBinaryFromReader);
      msg.setCutter(value);
      break;
    case 6:
      var value = new proto.pbs.OperateProcessParam;
      reader.readMessage(value,proto.pbs.OperateProcessParam.deserializeBinaryFromReader);
      msg.addOperateProcess(value);
      break;
    case 7:
      var value = new proto.pbs.MachineParam;
      reader.readMessage(value,proto.pbs.MachineParam.deserializeBinaryFromReader);
      msg.addMachine(value);
      break;
    case 8:
      var value = msg.getMachineProcessMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.pbs.MachineProcessParam.deserializeBinaryFromReader, "", new proto.pbs.MachineProcessParam());
         });
      break;
    case 9:
      var value = new proto.pbs.WorkpieceParam;
      reader.readMessage(value,proto.pbs.WorkpieceParam.deserializeBinaryFromReader);
      msg.addWorkpiece(value);
      break;
    case 10:
      var value = new proto.pbs.MachineEffectiveParam;
      reader.readMessage(value,proto.pbs.MachineEffectiveParam.deserializeBinaryFromReader);
      msg.setMachineEffective(value);
      break;
    case 11:
      var value = new proto.pbs.OperateLogParam;
      reader.readMessage(value,proto.pbs.OperateLogParam.deserializeBinaryFromReader);
      msg.setOperateLogParam(value);
      break;
    case 12:
      var value = new proto.pbs.ProfileParam;
      reader.readMessage(value,proto.pbs.ProfileParam.deserializeBinaryFromReader);
      msg.setProfileParam(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStandardType(value);
      break;
    case 14:
      var value = new proto.pbs.WorkpieceParamMilling;
      reader.readMessage(value,proto.pbs.WorkpieceParamMilling.deserializeBinaryFromReader);
      msg.addWorkpieceMilling(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setModelType(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkpieceImg(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 20:
      var value = new major_pb.Major;
      reader.readMessage(value,major_pb.Major.deserializeBinaryFromReader);
      msg.setMajor(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeletedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ScoreStandard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ScoreStandard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ScoreStandard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreStandard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBlank();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.BlankParam.serializeBinaryToWriter
    );
  }
  f = message.getClampParamList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.pbs.ClampParam.serializeBinaryToWriter
    );
  }
  f = message.getCutter();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pbs.CutterParam.serializeBinaryToWriter
    );
  }
  f = message.getOperateProcessList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.pbs.OperateProcessParam.serializeBinaryToWriter
    );
  }
  f = message.getMachineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.pbs.MachineParam.serializeBinaryToWriter
    );
  }
  f = message.getMachineProcessMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.pbs.MachineProcessParam.serializeBinaryToWriter);
  }
  f = message.getWorkpieceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.pbs.WorkpieceParam.serializeBinaryToWriter
    );
  }
  f = message.getMachineEffective();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.pbs.MachineEffectiveParam.serializeBinaryToWriter
    );
  }
  f = message.getOperateLogParam();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.pbs.OperateLogParam.serializeBinaryToWriter
    );
  }
  f = message.getProfileParam();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.pbs.ProfileParam.serializeBinaryToWriter
    );
  }
  f = message.getStandardType();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getWorkpieceMillingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.pbs.WorkpieceParamMilling.serializeBinaryToWriter
    );
  }
  f = message.getModelType();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getWorkpieceImg();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getMajor();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      major_pb.Major.serializeBinaryToWriter
    );
  }
  f = message.getDeletedAt();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.pbs.ScoreStandard.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.pbs.ScoreStandard.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional BlankParam blank = 3;
 * @return {?proto.pbs.BlankParam}
 */
proto.pbs.ScoreStandard.prototype.getBlank = function() {
  return /** @type{?proto.pbs.BlankParam} */ (
    jspb.Message.getWrapperField(this, proto.pbs.BlankParam, 3));
};


/**
 * @param {?proto.pbs.BlankParam|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setBlank = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearBlank = function() {
  return this.setBlank(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasBlank = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated ClampParam clamp_param = 4;
 * @return {!Array<!proto.pbs.ClampParam>}
 */
proto.pbs.ScoreStandard.prototype.getClampParamList = function() {
  return /** @type{!Array<!proto.pbs.ClampParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ClampParam, 4));
};


/**
 * @param {!Array<!proto.pbs.ClampParam>} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setClampParamList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.pbs.ClampParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ClampParam}
 */
proto.pbs.ScoreStandard.prototype.addClampParam = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.pbs.ClampParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearClampParamList = function() {
  return this.setClampParamList([]);
};


/**
 * optional CutterParam cutter = 5;
 * @return {?proto.pbs.CutterParam}
 */
proto.pbs.ScoreStandard.prototype.getCutter = function() {
  return /** @type{?proto.pbs.CutterParam} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CutterParam, 5));
};


/**
 * @param {?proto.pbs.CutterParam|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setCutter = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearCutter = function() {
  return this.setCutter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasCutter = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated OperateProcessParam operate_process = 6;
 * @return {!Array<!proto.pbs.OperateProcessParam>}
 */
proto.pbs.ScoreStandard.prototype.getOperateProcessList = function() {
  return /** @type{!Array<!proto.pbs.OperateProcessParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.OperateProcessParam, 6));
};


/**
 * @param {!Array<!proto.pbs.OperateProcessParam>} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setOperateProcessList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.pbs.OperateProcessParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.OperateProcessParam}
 */
proto.pbs.ScoreStandard.prototype.addOperateProcess = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.pbs.OperateProcessParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearOperateProcessList = function() {
  return this.setOperateProcessList([]);
};


/**
 * repeated MachineParam machine = 7;
 * @return {!Array<!proto.pbs.MachineParam>}
 */
proto.pbs.ScoreStandard.prototype.getMachineList = function() {
  return /** @type{!Array<!proto.pbs.MachineParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.MachineParam, 7));
};


/**
 * @param {!Array<!proto.pbs.MachineParam>} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setMachineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.pbs.MachineParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.MachineParam}
 */
proto.pbs.ScoreStandard.prototype.addMachine = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.pbs.MachineParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearMachineList = function() {
  return this.setMachineList([]);
};


/**
 * map<string, MachineProcessParam> machine_process = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.pbs.MachineProcessParam>}
 */
proto.pbs.ScoreStandard.prototype.getMachineProcessMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.pbs.MachineProcessParam>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      proto.pbs.MachineProcessParam));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearMachineProcessMap = function() {
  this.getMachineProcessMap().clear();
  return this;};


/**
 * repeated WorkpieceParam workpiece = 9;
 * @return {!Array<!proto.pbs.WorkpieceParam>}
 */
proto.pbs.ScoreStandard.prototype.getWorkpieceList = function() {
  return /** @type{!Array<!proto.pbs.WorkpieceParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.WorkpieceParam, 9));
};


/**
 * @param {!Array<!proto.pbs.WorkpieceParam>} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setWorkpieceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.pbs.WorkpieceParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.WorkpieceParam}
 */
proto.pbs.ScoreStandard.prototype.addWorkpiece = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.pbs.WorkpieceParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearWorkpieceList = function() {
  return this.setWorkpieceList([]);
};


/**
 * optional MachineEffectiveParam machine_effective = 10;
 * @return {?proto.pbs.MachineEffectiveParam}
 */
proto.pbs.ScoreStandard.prototype.getMachineEffective = function() {
  return /** @type{?proto.pbs.MachineEffectiveParam} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MachineEffectiveParam, 10));
};


/**
 * @param {?proto.pbs.MachineEffectiveParam|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setMachineEffective = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearMachineEffective = function() {
  return this.setMachineEffective(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasMachineEffective = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional OperateLogParam operate_log_param = 11;
 * @return {?proto.pbs.OperateLogParam}
 */
proto.pbs.ScoreStandard.prototype.getOperateLogParam = function() {
  return /** @type{?proto.pbs.OperateLogParam} */ (
    jspb.Message.getWrapperField(this, proto.pbs.OperateLogParam, 11));
};


/**
 * @param {?proto.pbs.OperateLogParam|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setOperateLogParam = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearOperateLogParam = function() {
  return this.setOperateLogParam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasOperateLogParam = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ProfileParam profile_param = 12;
 * @return {?proto.pbs.ProfileParam}
 */
proto.pbs.ScoreStandard.prototype.getProfileParam = function() {
  return /** @type{?proto.pbs.ProfileParam} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ProfileParam, 12));
};


/**
 * @param {?proto.pbs.ProfileParam|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setProfileParam = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearProfileParam = function() {
  return this.setProfileParam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasProfileParam = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int32 standard_type = 13;
 * @return {number}
 */
proto.pbs.ScoreStandard.prototype.getStandardType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setStandardType = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * repeated WorkpieceParamMilling workpiece_milling = 14;
 * @return {!Array<!proto.pbs.WorkpieceParamMilling>}
 */
proto.pbs.ScoreStandard.prototype.getWorkpieceMillingList = function() {
  return /** @type{!Array<!proto.pbs.WorkpieceParamMilling>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.WorkpieceParamMilling, 14));
};


/**
 * @param {!Array<!proto.pbs.WorkpieceParamMilling>} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setWorkpieceMillingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.pbs.WorkpieceParamMilling=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.WorkpieceParamMilling}
 */
proto.pbs.ScoreStandard.prototype.addWorkpieceMilling = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.pbs.WorkpieceParamMilling, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearWorkpieceMillingList = function() {
  return this.setWorkpieceMillingList([]);
};


/**
 * optional int32 model_type = 16;
 * @return {number}
 */
proto.pbs.ScoreStandard.prototype.getModelType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setModelType = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 created_at = 15;
 * @return {number}
 */
proto.pbs.ScoreStandard.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string workpiece_img = 17;
 * @return {string}
 */
proto.pbs.ScoreStandard.prototype.getWorkpieceImg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setWorkpieceImg = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string school_id = 18;
 * @return {string}
 */
proto.pbs.ScoreStandard.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string faculty_id = 19;
 * @return {string}
 */
proto.pbs.ScoreStandard.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional Major major = 20;
 * @return {?proto.pbs.Major}
 */
proto.pbs.ScoreStandard.prototype.getMajor = function() {
  return /** @type{?proto.pbs.Major} */ (
    jspb.Message.getWrapperField(this, major_pb.Major, 20));
};


/**
 * @param {?proto.pbs.Major|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setMajor = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearMajor = function() {
  return this.setMajor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasMajor = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional int64 deleted_at = 21;
 * @return {number}
 */
proto.pbs.ScoreStandard.prototype.getDeletedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setDeletedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


goog.object.extend(exports, proto.pbs);
