import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RoutesRoutingModule } from './routes-routing.module';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';
import { DialogAcceptComponent } from '@shared/components/dialog/accept/accept.component';
import { DialogFormComponent } from '@shared/components/dialog/form/form.component';

const COMPONENTS = [DashboardComponent, LoginComponent, RegisterComponent];
const COMPONENTS_DYNAMIC = [DialogAcceptComponent,DialogFormComponent];

@NgModule({
  imports: [SharedModule, RoutesRoutingModule,FormlyBootstrapModule],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC],
  entryComponents: COMPONENTS_DYNAMIC,
})
export class RoutesModule {}
