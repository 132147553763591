// source: user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var class_pb = require('./class_pb.js');
goog.object.extend(proto, class_pb);
goog.exportSymbol('proto.pbs.BatchImportReq', null, global);
goog.exportSymbol('proto.pbs.ChangePwdForm', null, global);
goog.exportSymbol('proto.pbs.Client', null, global);
goog.exportSymbol('proto.pbs.ExamId', null, global);
goog.exportSymbol('proto.pbs.LoginNums', null, global);
goog.exportSymbol('proto.pbs.LoginUser', null, global);
goog.exportSymbol('proto.pbs.RegisterReq', null, global);
goog.exportSymbol('proto.pbs.SmsCodeParam', null, global);
goog.exportSymbol('proto.pbs.UpdateUserSchoolReq', null, global);
goog.exportSymbol('proto.pbs.User', null, global);
goog.exportSymbol('proto.pbs.UserForm', null, global);
goog.exportSymbol('proto.pbs.UserId', null, global);
goog.exportSymbol('proto.pbs.UserListReq', null, global);
goog.exportSymbol('proto.pbs.UserSearchForm', null, global);
goog.exportSymbol('proto.pbs.Users', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.UpdateUserSchoolReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.UpdateUserSchoolReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.UpdateUserSchoolReq.displayName = 'proto.pbs.UpdateUserSchoolReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LoginNums = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.LoginNums, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LoginNums.displayName = 'proto.pbs.LoginNums';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.UserId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.UserId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.UserId.displayName = 'proto.pbs.UserId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.BatchImportReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.BatchImportReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.BatchImportReq.displayName = 'proto.pbs.BatchImportReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ExamId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ExamId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ExamId.displayName = 'proto.pbs.ExamId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.RegisterReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.RegisterReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.RegisterReq.displayName = 'proto.pbs.RegisterReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.UserListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.UserListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.UserListReq.displayName = 'proto.pbs.UserListReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SmsCodeParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SmsCodeParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SmsCodeParam.displayName = 'proto.pbs.SmsCodeParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LoginUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.LoginUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LoginUser.displayName = 'proto.pbs.LoginUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ChangePwdForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ChangePwdForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ChangePwdForm.displayName = 'proto.pbs.ChangePwdForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.UserForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.UserForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.UserForm.displayName = 'proto.pbs.UserForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Users = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.Users.repeatedFields_, null);
};
goog.inherits(proto.pbs.Users, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Users.displayName = 'proto.pbs.Users';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.UserSearchForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.UserSearchForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.UserSearchForm.displayName = 'proto.pbs.UserSearchForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Client = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.Client, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Client.displayName = 'proto.pbs.Client';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.User.repeatedFields_, null);
};
goog.inherits(proto.pbs.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.User.displayName = 'proto.pbs.User';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.UpdateUserSchoolReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.UpdateUserSchoolReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.UpdateUserSchoolReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UpdateUserSchoolReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userFname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 2, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 3, 0),
    facultyId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    majorId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    classId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.UpdateUserSchoolReq}
 */
proto.pbs.UpdateUserSchoolReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.UpdateUserSchoolReq;
  return proto.pbs.UpdateUserSchoolReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.UpdateUserSchoolReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.UpdateUserSchoolReq}
 */
proto.pbs.UpdateUserSchoolReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserFname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGender(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.UpdateUserSchoolReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.UpdateUserSchoolReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.UpdateUserSchoolReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UpdateUserSchoolReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserFname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGender();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMajorId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getClassId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string user_fname = 1;
 * @return {string}
 */
proto.pbs.UpdateUserSchoolReq.prototype.getUserFname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UpdateUserSchoolReq} returns this
 */
proto.pbs.UpdateUserSchoolReq.prototype.setUserFname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mobile = 2;
 * @return {string}
 */
proto.pbs.UpdateUserSchoolReq.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UpdateUserSchoolReq} returns this
 */
proto.pbs.UpdateUserSchoolReq.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 gender = 3;
 * @return {number}
 */
proto.pbs.UpdateUserSchoolReq.prototype.getGender = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.UpdateUserSchoolReq} returns this
 */
proto.pbs.UpdateUserSchoolReq.prototype.setGender = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string faculty_id = 4;
 * @return {string}
 */
proto.pbs.UpdateUserSchoolReq.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UpdateUserSchoolReq} returns this
 */
proto.pbs.UpdateUserSchoolReq.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string major_id = 5;
 * @return {string}
 */
proto.pbs.UpdateUserSchoolReq.prototype.getMajorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UpdateUserSchoolReq} returns this
 */
proto.pbs.UpdateUserSchoolReq.prototype.setMajorId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string class_id = 6;
 * @return {string}
 */
proto.pbs.UpdateUserSchoolReq.prototype.getClassId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UpdateUserSchoolReq} returns this
 */
proto.pbs.UpdateUserSchoolReq.prototype.setClassId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LoginNums.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LoginNums.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LoginNums} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LoginNums.toObject = function(includeInstance, msg) {
  var f, obj = {
    num: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LoginNums}
 */
proto.pbs.LoginNums.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LoginNums;
  return proto.pbs.LoginNums.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LoginNums} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LoginNums}
 */
proto.pbs.LoginNums.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LoginNums.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LoginNums.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LoginNums} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LoginNums.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 num = 1;
 * @return {number}
 */
proto.pbs.LoginNums.prototype.getNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LoginNums} returns this
 */
proto.pbs.LoginNums.prototype.setNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.UserId.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.UserId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.UserId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UserId.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.UserId}
 */
proto.pbs.UserId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.UserId;
  return proto.pbs.UserId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.UserId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.UserId}
 */
proto.pbs.UserId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.UserId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.UserId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.UserId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UserId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.pbs.UserId.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserId} returns this
 */
proto.pbs.UserId.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.BatchImportReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.BatchImportReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.BatchImportReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.BatchImportReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    schoolId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    facultyId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    majorId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    classId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    filePath: jspb.Message.getFieldWithDefault(msg, 5, ""),
    isTrial: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.BatchImportReq}
 */
proto.pbs.BatchImportReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.BatchImportReq;
  return proto.pbs.BatchImportReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.BatchImportReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.BatchImportReq}
 */
proto.pbs.BatchImportReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilePath(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIsTrial(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.BatchImportReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.BatchImportReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.BatchImportReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.BatchImportReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMajorId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClassId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFilePath();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIsTrial();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional string school_id = 1;
 * @return {string}
 */
proto.pbs.BatchImportReq.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.BatchImportReq} returns this
 */
proto.pbs.BatchImportReq.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string faculty_id = 2;
 * @return {string}
 */
proto.pbs.BatchImportReq.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.BatchImportReq} returns this
 */
proto.pbs.BatchImportReq.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string major_id = 3;
 * @return {string}
 */
proto.pbs.BatchImportReq.prototype.getMajorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.BatchImportReq} returns this
 */
proto.pbs.BatchImportReq.prototype.setMajorId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string class_id = 4;
 * @return {string}
 */
proto.pbs.BatchImportReq.prototype.getClassId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.BatchImportReq} returns this
 */
proto.pbs.BatchImportReq.prototype.setClassId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string file_path = 5;
 * @return {string}
 */
proto.pbs.BatchImportReq.prototype.getFilePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.BatchImportReq} returns this
 */
proto.pbs.BatchImportReq.prototype.setFilePath = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 is_trial = 6;
 * @return {number}
 */
proto.pbs.BatchImportReq.prototype.getIsTrial = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.BatchImportReq} returns this
 */
proto.pbs.BatchImportReq.prototype.setIsTrial = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ExamId.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ExamId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ExamId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ExamId.toObject = function(includeInstance, msg) {
  var f, obj = {
    examId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ExamId}
 */
proto.pbs.ExamId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ExamId;
  return proto.pbs.ExamId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ExamId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ExamId}
 */
proto.pbs.ExamId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ExamId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ExamId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ExamId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ExamId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string exam_id = 1;
 * @return {string}
 */
proto.pbs.ExamId.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExamId} returns this
 */
proto.pbs.ExamId.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.RegisterReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.RegisterReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.RegisterReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.RegisterReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobile: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    captcha: jspb.Message.getFieldWithDefault(msg, 3, ""),
    province: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    district: jspb.Message.getFieldWithDefault(msg, 6, ""),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    userType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    schoolId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    workAddress: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.RegisterReq}
 */
proto.pbs.RegisterReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.RegisterReq;
  return proto.pbs.RegisterReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.RegisterReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.RegisterReq}
 */
proto.pbs.RegisterReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaptcha(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvince(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistrict(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.RegisterReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.RegisterReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.RegisterReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.RegisterReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCaptcha();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProvince();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDistrict();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getUserType();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getWorkAddress();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string mobile = 1;
 * @return {string}
 */
proto.pbs.RegisterReq.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegisterReq} returns this
 */
proto.pbs.RegisterReq.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.pbs.RegisterReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegisterReq} returns this
 */
proto.pbs.RegisterReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string captcha = 3;
 * @return {string}
 */
proto.pbs.RegisterReq.prototype.getCaptcha = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegisterReq} returns this
 */
proto.pbs.RegisterReq.prototype.setCaptcha = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string province = 4;
 * @return {string}
 */
proto.pbs.RegisterReq.prototype.getProvince = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegisterReq} returns this
 */
proto.pbs.RegisterReq.prototype.setProvince = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.pbs.RegisterReq.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegisterReq} returns this
 */
proto.pbs.RegisterReq.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string district = 6;
 * @return {string}
 */
proto.pbs.RegisterReq.prototype.getDistrict = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegisterReq} returns this
 */
proto.pbs.RegisterReq.prototype.setDistrict = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double longitude = 7;
 * @return {number}
 */
proto.pbs.RegisterReq.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.RegisterReq} returns this
 */
proto.pbs.RegisterReq.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double latitude = 8;
 * @return {number}
 */
proto.pbs.RegisterReq.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.RegisterReq} returns this
 */
proto.pbs.RegisterReq.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int32 user_type = 9;
 * @return {number}
 */
proto.pbs.RegisterReq.prototype.getUserType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.RegisterReq} returns this
 */
proto.pbs.RegisterReq.prototype.setUserType = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string school_id = 10;
 * @return {string}
 */
proto.pbs.RegisterReq.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegisterReq} returns this
 */
proto.pbs.RegisterReq.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string work_address = 11;
 * @return {string}
 */
proto.pbs.RegisterReq.prototype.getWorkAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegisterReq} returns this
 */
proto.pbs.RegisterReq.prototype.setWorkAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.UserListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.UserListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.UserListReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UserListReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    keyword: jspb.Message.getFieldWithDefault(msg, 2, ""),
    schoolId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    facultyId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    majorId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    classId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    page: jspb.Message.getFieldWithDefault(msg, 7, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 8, 0),
    mobile: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.UserListReq}
 */
proto.pbs.UserListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.UserListReq;
  return proto.pbs.UserListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.UserListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.UserListReq}
 */
proto.pbs.UserListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.UserListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.UserListReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.UserListReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UserListReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMajorId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getClassId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 user_type = 1;
 * @return {number}
 */
proto.pbs.UserListReq.prototype.getUserType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.UserListReq} returns this
 */
proto.pbs.UserListReq.prototype.setUserType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string keyword = 2;
 * @return {string}
 */
proto.pbs.UserListReq.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserListReq} returns this
 */
proto.pbs.UserListReq.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string school_id = 3;
 * @return {string}
 */
proto.pbs.UserListReq.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserListReq} returns this
 */
proto.pbs.UserListReq.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string faculty_id = 4;
 * @return {string}
 */
proto.pbs.UserListReq.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserListReq} returns this
 */
proto.pbs.UserListReq.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string major_id = 5;
 * @return {string}
 */
proto.pbs.UserListReq.prototype.getMajorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserListReq} returns this
 */
proto.pbs.UserListReq.prototype.setMajorId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string class_id = 6;
 * @return {string}
 */
proto.pbs.UserListReq.prototype.getClassId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserListReq} returns this
 */
proto.pbs.UserListReq.prototype.setClassId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 page = 7;
 * @return {number}
 */
proto.pbs.UserListReq.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.UserListReq} returns this
 */
proto.pbs.UserListReq.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 page_size = 8;
 * @return {number}
 */
proto.pbs.UserListReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.UserListReq} returns this
 */
proto.pbs.UserListReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string mobile = 9;
 * @return {string}
 */
proto.pbs.UserListReq.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserListReq} returns this
 */
proto.pbs.UserListReq.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SmsCodeParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SmsCodeParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SmsCodeParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SmsCodeParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobile: jspb.Message.getFieldWithDefault(msg, 1, ""),
    smsType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SmsCodeParam}
 */
proto.pbs.SmsCodeParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SmsCodeParam;
  return proto.pbs.SmsCodeParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SmsCodeParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SmsCodeParam}
 */
proto.pbs.SmsCodeParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSmsType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SmsCodeParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SmsCodeParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SmsCodeParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SmsCodeParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSmsType();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string mobile = 1;
 * @return {string}
 */
proto.pbs.SmsCodeParam.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SmsCodeParam} returns this
 */
proto.pbs.SmsCodeParam.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 sms_type = 2;
 * @return {number}
 */
proto.pbs.SmsCodeParam.prototype.getSmsType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SmsCodeParam} returns this
 */
proto.pbs.SmsCodeParam.prototype.setSmsType = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LoginUser.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LoginUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LoginUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LoginUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pwd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imgcode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    loginType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    client: (f = msg.getClient()) && proto.pbs.Client.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LoginUser}
 */
proto.pbs.LoginUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LoginUser;
  return proto.pbs.LoginUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LoginUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LoginUser}
 */
proto.pbs.LoginUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPwd(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImgcode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLoginType(value);
      break;
    case 6:
      var value = new proto.pbs.Client;
      reader.readMessage(value,proto.pbs.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LoginUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LoginUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LoginUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LoginUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPwd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImgcode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLoginType();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.pbs.Client.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.pbs.LoginUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LoginUser} returns this
 */
proto.pbs.LoginUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pwd = 2;
 * @return {string}
 */
proto.pbs.LoginUser.prototype.getPwd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LoginUser} returns this
 */
proto.pbs.LoginUser.prototype.setPwd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.pbs.LoginUser.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LoginUser} returns this
 */
proto.pbs.LoginUser.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string imgcode = 4;
 * @return {string}
 */
proto.pbs.LoginUser.prototype.getImgcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LoginUser} returns this
 */
proto.pbs.LoginUser.prototype.setImgcode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 login_type = 5;
 * @return {number}
 */
proto.pbs.LoginUser.prototype.getLoginType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LoginUser} returns this
 */
proto.pbs.LoginUser.prototype.setLoginType = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional Client client = 6;
 * @return {?proto.pbs.Client}
 */
proto.pbs.LoginUser.prototype.getClient = function() {
  return /** @type{?proto.pbs.Client} */ (
    jspb.Message.getWrapperField(this, proto.pbs.Client, 6));
};


/**
 * @param {?proto.pbs.Client|undefined} value
 * @return {!proto.pbs.LoginUser} returns this
*/
proto.pbs.LoginUser.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.LoginUser} returns this
 */
proto.pbs.LoginUser.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.LoginUser.prototype.hasClient = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ChangePwdForm.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ChangePwdForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ChangePwdForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ChangePwdForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    old: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pwd: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ChangePwdForm}
 */
proto.pbs.ChangePwdForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ChangePwdForm;
  return proto.pbs.ChangePwdForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ChangePwdForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ChangePwdForm}
 */
proto.pbs.ChangePwdForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOld(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPwd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ChangePwdForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ChangePwdForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ChangePwdForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ChangePwdForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOld();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPwd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string old = 1;
 * @return {string}
 */
proto.pbs.ChangePwdForm.prototype.getOld = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ChangePwdForm} returns this
 */
proto.pbs.ChangePwdForm.prototype.setOld = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pwd = 2;
 * @return {string}
 */
proto.pbs.ChangePwdForm.prototype.getPwd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ChangePwdForm} returns this
 */
proto.pbs.ChangePwdForm.prototype.setPwd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.UserForm.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.UserForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.UserForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UserForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    fname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 2, 0),
    birthday: jspb.Message.getFieldWithDefault(msg, 3, 0),
    idcard: jspb.Message.getFieldWithDefault(msg, 14, ""),
    nation: jspb.Message.getFieldWithDefault(msg, 24, ""),
    degree: jspb.Message.getFieldWithDefault(msg, 25, 0),
    avatar: jspb.Message.getFieldWithDefault(msg, 26, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 6, ""),
    facultyId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    majorId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    classId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.UserForm}
 */
proto.pbs.UserForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.UserForm;
  return proto.pbs.UserForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.UserForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.UserForm}
 */
proto.pbs.UserForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFname(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGender(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBirthday(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdcard(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setNation(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDegree(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatar(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.UserForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.UserForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.UserForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UserForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGender();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBirthday();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getIdcard();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getNation();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getDegree();
  if (f !== 0) {
    writer.writeUint32(
      25,
      f
    );
  }
  f = message.getAvatar();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMajorId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getClassId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string fname = 1;
 * @return {string}
 */
proto.pbs.UserForm.prototype.getFname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserForm} returns this
 */
proto.pbs.UserForm.prototype.setFname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 gender = 2;
 * @return {number}
 */
proto.pbs.UserForm.prototype.getGender = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.UserForm} returns this
 */
proto.pbs.UserForm.prototype.setGender = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 birthday = 3;
 * @return {number}
 */
proto.pbs.UserForm.prototype.getBirthday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.UserForm} returns this
 */
proto.pbs.UserForm.prototype.setBirthday = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string idCard = 14;
 * @return {string}
 */
proto.pbs.UserForm.prototype.getIdcard = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserForm} returns this
 */
proto.pbs.UserForm.prototype.setIdcard = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string nation = 24;
 * @return {string}
 */
proto.pbs.UserForm.prototype.getNation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserForm} returns this
 */
proto.pbs.UserForm.prototype.setNation = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional uint32 degree = 25;
 * @return {number}
 */
proto.pbs.UserForm.prototype.getDegree = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.UserForm} returns this
 */
proto.pbs.UserForm.prototype.setDegree = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional string avatar = 26;
 * @return {string}
 */
proto.pbs.UserForm.prototype.getAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserForm} returns this
 */
proto.pbs.UserForm.prototype.setAvatar = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string mobile = 6;
 * @return {string}
 */
proto.pbs.UserForm.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserForm} returns this
 */
proto.pbs.UserForm.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string faculty_id = 7;
 * @return {string}
 */
proto.pbs.UserForm.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserForm} returns this
 */
proto.pbs.UserForm.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string major_id = 8;
 * @return {string}
 */
proto.pbs.UserForm.prototype.getMajorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserForm} returns this
 */
proto.pbs.UserForm.prototype.setMajorId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string class_id = 9;
 * @return {string}
 */
proto.pbs.UserForm.prototype.getClassId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserForm} returns this
 */
proto.pbs.UserForm.prototype.setClassId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.Users.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Users.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Users.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Users} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Users.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.pbs.User.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Users}
 */
proto.pbs.Users.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Users;
  return proto.pbs.Users.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Users} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Users}
 */
proto.pbs.Users.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.User;
      reader.readMessage(value,proto.pbs.User.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPagesize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Users.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Users.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Users} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Users.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.User.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated User data = 1;
 * @return {!Array<!proto.pbs.User>}
 */
proto.pbs.Users.prototype.getDataList = function() {
  return /** @type{!Array<!proto.pbs.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.User, 1));
};


/**
 * @param {!Array<!proto.pbs.User>} value
 * @return {!proto.pbs.Users} returns this
*/
proto.pbs.Users.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.User}
 */
proto.pbs.Users.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Users} returns this
 */
proto.pbs.Users.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.pbs.Users.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Users} returns this
 */
proto.pbs.Users.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pageSize = 3;
 * @return {number}
 */
proto.pbs.Users.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Users} returns this
 */
proto.pbs.Users.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.UserSearchForm.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.UserSearchForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.UserSearchForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UserSearchForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    page: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.UserSearchForm}
 */
proto.pbs.UserSearchForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.UserSearchForm;
  return proto.pbs.UserSearchForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.UserSearchForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.UserSearchForm}
 */
proto.pbs.UserSearchForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.UserSearchForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.UserSearchForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.UserSearchForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UserSearchForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string keyword = 1;
 * @return {string}
 */
proto.pbs.UserSearchForm.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserSearchForm} returns this
 */
proto.pbs.UserSearchForm.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 pageSize = 2;
 * @return {number}
 */
proto.pbs.UserSearchForm.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.UserSearchForm} returns this
 */
proto.pbs.UserSearchForm.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 page = 3;
 * @return {number}
 */
proto.pbs.UserSearchForm.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.UserSearchForm} returns this
 */
proto.pbs.UserSearchForm.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Client.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Client.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Client} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Client.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientOutIp: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientInIp: jspb.Message.getFieldWithDefault(msg, 2, ""),
    clientCpu: jspb.Message.getFieldWithDefault(msg, 3, ""),
    clientMem: jspb.Message.getFieldWithDefault(msg, 4, ""),
    clientGraphics: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Client}
 */
proto.pbs.Client.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Client;
  return proto.pbs.Client.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Client} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Client}
 */
proto.pbs.Client.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientOutIp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientInIp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientCpu(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientMem(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientGraphics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Client.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Client.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Client} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Client.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientOutIp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientInIp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClientCpu();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClientMem();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClientGraphics();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string client_out_ip = 1;
 * @return {string}
 */
proto.pbs.Client.prototype.getClientOutIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Client} returns this
 */
proto.pbs.Client.prototype.setClientOutIp = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_in_ip = 2;
 * @return {string}
 */
proto.pbs.Client.prototype.getClientInIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Client} returns this
 */
proto.pbs.Client.prototype.setClientInIp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string client_cpu = 3;
 * @return {string}
 */
proto.pbs.Client.prototype.getClientCpu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Client} returns this
 */
proto.pbs.Client.prototype.setClientCpu = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string client_mem = 4;
 * @return {string}
 */
proto.pbs.Client.prototype.getClientMem = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Client} returns this
 */
proto.pbs.Client.prototype.setClientMem = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string client_graphics = 5;
 * @return {string}
 */
proto.pbs.Client.prototype.getClientGraphics = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Client} returns this
 */
proto.pbs.Client.prototype.setClientGraphics = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.User.repeatedFields_ = [23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.User.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userfname: jspb.Message.getFieldWithDefault(msg, 12, ""),
    usergender: jspb.Message.getFieldWithDefault(msg, 13, 0),
    useridcard: jspb.Message.getFieldWithDefault(msg, 14, ""),
    usernation: jspb.Message.getFieldWithDefault(msg, 24, ""),
    userdegree: jspb.Message.getFieldWithDefault(msg, 25, 0),
    useravatar: jspb.Message.getFieldWithDefault(msg, 26, ""),
    usertype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    userpasswd: jspb.Message.getFieldWithDefault(msg, 4, ""),
    useremail: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userbirthday: jspb.Message.getFieldWithDefault(msg, 27, 0),
    usermobile: jspb.Message.getFieldWithDefault(msg, 6, ""),
    usertoken: jspb.Message.getFieldWithDefault(msg, 7, ""),
    userstatus: jspb.Message.getFieldWithDefault(msg, 8, 0),
    userupdateat: jspb.Message.getFieldWithDefault(msg, 9, 0),
    userdeleteat: jspb.Message.getFieldWithDefault(msg, 19, 0),
    usercreateat: jspb.Message.getFieldWithDefault(msg, 10, 0),
    userregisterip: jspb.Message.getFieldWithDefault(msg, 11, ""),
    useraddress: jspb.Message.getFieldWithDefault(msg, 15, ""),
    userregisterposition: jspb.Message.getFieldWithDefault(msg, 16, ""),
    pb_class: (f = msg.getClass()) && class_pb.Class.toObject(includeInstance, f),
    majorId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    facultyId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    schoolId: jspb.Message.getFieldWithDefault(msg, 22, ""),
    classesList: jspb.Message.toObjectList(msg.getClassesList(),
    class_pb.Class.toObject, includeInstance),
    expireTime: jspb.Message.getFieldWithDefault(msg, 18, 0),
    userClient: (f = msg.getUserClient()) && proto.pbs.Client.toObject(includeInstance, f),
    openId: jspb.Message.getFieldWithDefault(msg, 29, ""),
    workAddress: jspb.Message.getFieldWithDefault(msg, 32, ""),
    operateRecordNum: jspb.Message.getFieldWithDefault(msg, 30, 0),
    lastActiveTime: jspb.Message.getFieldWithDefault(msg, 31, 0),
    isTrial: jspb.Message.getFieldWithDefault(msg, 33, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.User}
 */
proto.pbs.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.User;
  return proto.pbs.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.User}
 */
proto.pbs.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserfname(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsergender(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setUseridcard(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsernation(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserdegree(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setUseravatar(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsertype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserpasswd(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUseremail(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserbirthday(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsermobile(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsertoken(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserstatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserupdateat(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserdeleteat(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsercreateat(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserregisterip(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUseraddress(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserregisterposition(value);
      break;
    case 17:
      var value = new class_pb.Class;
      reader.readMessage(value,class_pb.Class.deserializeBinaryFromReader);
      msg.setClass(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    case 23:
      var value = new class_pb.Class;
      reader.readMessage(value,class_pb.Class.deserializeBinaryFromReader);
      msg.addClasses(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpireTime(value);
      break;
    case 28:
      var value = new proto.pbs.Client;
      reader.readMessage(value,proto.pbs.Client.deserializeBinaryFromReader);
      msg.setUserClient(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpenId(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkAddress(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOperateRecordNum(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastActiveTime(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIsTrial(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserfname();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUsergender();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getUseridcard();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUsernation();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getUserdegree();
  if (f !== 0) {
    writer.writeUint32(
      25,
      f
    );
  }
  f = message.getUseravatar();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getUsertype();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getUserpasswd();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUseremail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserbirthday();
  if (f !== 0) {
    writer.writeInt64(
      27,
      f
    );
  }
  f = message.getUsermobile();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUsertoken();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUserstatus();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getUserupdateat();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getUserdeleteat();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getUsercreateat();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getUserregisterip();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUseraddress();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUserregisterposition();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getClass();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      class_pb.Class.serializeBinaryToWriter
    );
  }
  f = message.getMajorId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getClassesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      class_pb.Class.serializeBinaryToWriter
    );
  }
  f = message.getExpireTime();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getUserClient();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.pbs.Client.serializeBinaryToWriter
    );
  }
  f = message.getOpenId();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getWorkAddress();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getOperateRecordNum();
  if (f !== 0) {
    writer.writeInt64(
      30,
      f
    );
  }
  f = message.getLastActiveTime();
  if (f !== 0) {
    writer.writeInt64(
      31,
      f
    );
  }
  f = message.getIsTrial();
  if (f !== 0) {
    writer.writeUint32(
      33,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.pbs.User.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string userName = 2;
 * @return {string}
 */
proto.pbs.User.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string userFname = 12;
 * @return {string}
 */
proto.pbs.User.prototype.getUserfname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUserfname = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional uint32 userGender = 13;
 * @return {number}
 */
proto.pbs.User.prototype.getUsergender = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUsergender = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string userIdCard = 14;
 * @return {string}
 */
proto.pbs.User.prototype.getUseridcard = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUseridcard = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string userNation = 24;
 * @return {string}
 */
proto.pbs.User.prototype.getUsernation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUsernation = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional uint32 userDegree = 25;
 * @return {number}
 */
proto.pbs.User.prototype.getUserdegree = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUserdegree = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional string userAvatar = 26;
 * @return {string}
 */
proto.pbs.User.prototype.getUseravatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUseravatar = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional uint32 userType = 3;
 * @return {number}
 */
proto.pbs.User.prototype.getUsertype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string userPasswd = 4;
 * @return {string}
 */
proto.pbs.User.prototype.getUserpasswd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUserpasswd = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string userEmail = 5;
 * @return {string}
 */
proto.pbs.User.prototype.getUseremail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUseremail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 userBirthday = 27;
 * @return {number}
 */
proto.pbs.User.prototype.getUserbirthday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUserbirthday = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional string userMobile = 6;
 * @return {string}
 */
proto.pbs.User.prototype.getUsermobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUsermobile = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string userToken = 7;
 * @return {string}
 */
proto.pbs.User.prototype.getUsertoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUsertoken = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 userStatus = 8;
 * @return {number}
 */
proto.pbs.User.prototype.getUserstatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUserstatus = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 userUpdateAt = 9;
 * @return {number}
 */
proto.pbs.User.prototype.getUserupdateat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUserupdateat = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 userDeleteAt = 19;
 * @return {number}
 */
proto.pbs.User.prototype.getUserdeleteat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUserdeleteat = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 userCreateAt = 10;
 * @return {number}
 */
proto.pbs.User.prototype.getUsercreateat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUsercreateat = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string userRegisterIp = 11;
 * @return {string}
 */
proto.pbs.User.prototype.getUserregisterip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUserregisterip = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string userAddress = 15;
 * @return {string}
 */
proto.pbs.User.prototype.getUseraddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUseraddress = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string userRegisterPosition = 16;
 * @return {string}
 */
proto.pbs.User.prototype.getUserregisterposition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUserregisterposition = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional Class class = 17;
 * @return {?proto.pbs.Class}
 */
proto.pbs.User.prototype.getClass = function() {
  return /** @type{?proto.pbs.Class} */ (
    jspb.Message.getWrapperField(this, class_pb.Class, 17));
};


/**
 * @param {?proto.pbs.Class|undefined} value
 * @return {!proto.pbs.User} returns this
*/
proto.pbs.User.prototype.setClass = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.clearClass = function() {
  return this.setClass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.User.prototype.hasClass = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string major_id = 20;
 * @return {string}
 */
proto.pbs.User.prototype.getMajorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setMajorId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string faculty_id = 21;
 * @return {string}
 */
proto.pbs.User.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string school_id = 22;
 * @return {string}
 */
proto.pbs.User.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * repeated Class classes = 23;
 * @return {!Array<!proto.pbs.Class>}
 */
proto.pbs.User.prototype.getClassesList = function() {
  return /** @type{!Array<!proto.pbs.Class>} */ (
    jspb.Message.getRepeatedWrapperField(this, class_pb.Class, 23));
};


/**
 * @param {!Array<!proto.pbs.Class>} value
 * @return {!proto.pbs.User} returns this
*/
proto.pbs.User.prototype.setClassesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.pbs.Class=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Class}
 */
proto.pbs.User.prototype.addClasses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.pbs.Class, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.clearClassesList = function() {
  return this.setClassesList([]);
};


/**
 * optional int64 expire_time = 18;
 * @return {number}
 */
proto.pbs.User.prototype.getExpireTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setExpireTime = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional Client user_client = 28;
 * @return {?proto.pbs.Client}
 */
proto.pbs.User.prototype.getUserClient = function() {
  return /** @type{?proto.pbs.Client} */ (
    jspb.Message.getWrapperField(this, proto.pbs.Client, 28));
};


/**
 * @param {?proto.pbs.Client|undefined} value
 * @return {!proto.pbs.User} returns this
*/
proto.pbs.User.prototype.setUserClient = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.clearUserClient = function() {
  return this.setUserClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.User.prototype.hasUserClient = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional string open_id = 29;
 * @return {string}
 */
proto.pbs.User.prototype.getOpenId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setOpenId = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string work_address = 32;
 * @return {string}
 */
proto.pbs.User.prototype.getWorkAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setWorkAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional int64 operate_record_num = 30;
 * @return {number}
 */
proto.pbs.User.prototype.getOperateRecordNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setOperateRecordNum = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional int64 last_active_time = 31;
 * @return {number}
 */
proto.pbs.User.prototype.getLastActiveTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setLastActiveTime = function(value) {
  return jspb.Message.setProto3IntField(this, 31, value);
};


/**
 * optional uint32 is_trial = 33;
 * @return {number}
 */
proto.pbs.User.prototype.getIsTrial = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setIsTrial = function(value) {
  return jspb.Message.setProto3IntField(this, 33, value);
};


goog.object.extend(exports, proto.pbs);
