/**
 * @fileoverview gRPC-Web generated client stub for pbs
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var common_pb = require('./common_pb.js')

var user_pb = require('./user_pb.js')

var major_pb = require('./major_pb.js')

var cutter_pb = require('./cutter_pb.js')

var blank_pb = require('./blank_pb.js')

var score_standard_pb = require('./score_standard_pb.js')
const proto = {};
proto.pbs = require('./paper_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.PaperServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.PaperServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Empty,
 *   !proto.pbs.Questions>}
 */
const methodDescriptor_PaperService_ListQuestion = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/ListQuestion',
  grpc.web.MethodType.UNARY,
  common_pb.Empty,
  proto.pbs.Questions,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Questions.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Empty,
 *   !proto.pbs.Questions>}
 */
const methodInfo_PaperService_ListQuestion = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Questions,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Questions.deserializeBinary
);


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Questions)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Questions>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.listQuestion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/ListQuestion',
      request,
      metadata || {},
      methodDescriptor_PaperService_ListQuestion,
      callback);
};


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Questions>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.listQuestion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/ListQuestion',
      request,
      metadata || {},
      methodDescriptor_PaperService_ListQuestion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Question,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_PaperService_CreateQuestion = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/CreateQuestion',
  grpc.web.MethodType.UNARY,
  proto.pbs.Question,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Question} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Question,
 *   !proto.pbs.Empty>}
 */
const methodInfo_PaperService_CreateQuestion = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Question} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Question} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.createQuestion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/CreateQuestion',
      request,
      metadata || {},
      methodDescriptor_PaperService_CreateQuestion,
      callback);
};


/**
 * @param {!proto.pbs.Question} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.createQuestion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/CreateQuestion',
      request,
      metadata || {},
      methodDescriptor_PaperService_CreateQuestion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Question,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_PaperService_DeleteQuestion = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/DeleteQuestion',
  grpc.web.MethodType.UNARY,
  proto.pbs.Question,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Question} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Question,
 *   !proto.pbs.Empty>}
 */
const methodInfo_PaperService_DeleteQuestion = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Question} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Question} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.deleteQuestion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/DeleteQuestion',
      request,
      metadata || {},
      methodDescriptor_PaperService_DeleteQuestion,
      callback);
};


/**
 * @param {!proto.pbs.Question} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.deleteQuestion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/DeleteQuestion',
      request,
      metadata || {},
      methodDescriptor_PaperService_DeleteQuestion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Question,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_PaperService_EditQuestion = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/EditQuestion',
  grpc.web.MethodType.UNARY,
  proto.pbs.Question,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Question} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Question,
 *   !proto.pbs.Empty>}
 */
const methodInfo_PaperService_EditQuestion = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Question} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Question} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.editQuestion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/EditQuestion',
      request,
      metadata || {},
      methodDescriptor_PaperService_EditQuestion,
      callback);
};


/**
 * @param {!proto.pbs.Question} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.editQuestion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/EditQuestion',
      request,
      metadata || {},
      methodDescriptor_PaperService_EditQuestion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Question,
 *   !proto.pbs.Question>}
 */
const methodDescriptor_PaperService_ViewQuestion = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/ViewQuestion',
  grpc.web.MethodType.UNARY,
  proto.pbs.Question,
  proto.pbs.Question,
  /**
   * @param {!proto.pbs.Question} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Question.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Question,
 *   !proto.pbs.Question>}
 */
const methodInfo_PaperService_ViewQuestion = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Question,
  /**
   * @param {!proto.pbs.Question} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Question.deserializeBinary
);


/**
 * @param {!proto.pbs.Question} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Question)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Question>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.viewQuestion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/ViewQuestion',
      request,
      metadata || {},
      methodDescriptor_PaperService_ViewQuestion,
      callback);
};


/**
 * @param {!proto.pbs.Question} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Question>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.viewQuestion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/ViewQuestion',
      request,
      metadata || {},
      methodDescriptor_PaperService_ViewQuestion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.QuestionSearchForm,
 *   !proto.pbs.Questions>}
 */
const methodDescriptor_PaperService_ListQuestionByMajor = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/ListQuestionByMajor',
  grpc.web.MethodType.UNARY,
  proto.pbs.QuestionSearchForm,
  proto.pbs.Questions,
  /**
   * @param {!proto.pbs.QuestionSearchForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Questions.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.QuestionSearchForm,
 *   !proto.pbs.Questions>}
 */
const methodInfo_PaperService_ListQuestionByMajor = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Questions,
  /**
   * @param {!proto.pbs.QuestionSearchForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Questions.deserializeBinary
);


/**
 * @param {!proto.pbs.QuestionSearchForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Questions)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Questions>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.listQuestionByMajor =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/ListQuestionByMajor',
      request,
      metadata || {},
      methodDescriptor_PaperService_ListQuestionByMajor,
      callback);
};


/**
 * @param {!proto.pbs.QuestionSearchForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Questions>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.listQuestionByMajor =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/ListQuestionByMajor',
      request,
      metadata || {},
      methodDescriptor_PaperService_ListQuestionByMajor);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.QuestionSearchForm,
 *   !proto.pbs.Questions>}
 */
const methodDescriptor_PaperService_SearchQuestion = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/SearchQuestion',
  grpc.web.MethodType.UNARY,
  proto.pbs.QuestionSearchForm,
  proto.pbs.Questions,
  /**
   * @param {!proto.pbs.QuestionSearchForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Questions.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.QuestionSearchForm,
 *   !proto.pbs.Questions>}
 */
const methodInfo_PaperService_SearchQuestion = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Questions,
  /**
   * @param {!proto.pbs.QuestionSearchForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Questions.deserializeBinary
);


/**
 * @param {!proto.pbs.QuestionSearchForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Questions)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Questions>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.searchQuestion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/SearchQuestion',
      request,
      metadata || {},
      methodDescriptor_PaperService_SearchQuestion,
      callback);
};


/**
 * @param {!proto.pbs.QuestionSearchForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Questions>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.searchQuestion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/SearchQuestion',
      request,
      metadata || {},
      methodDescriptor_PaperService_SearchQuestion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Empty,
 *   !proto.pbs.Papers>}
 */
const methodDescriptor_PaperService_List = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/List',
  grpc.web.MethodType.UNARY,
  common_pb.Empty,
  proto.pbs.Papers,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Papers.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Empty,
 *   !proto.pbs.Papers>}
 */
const methodInfo_PaperService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Papers,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Papers.deserializeBinary
);


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Papers)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Papers>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/List',
      request,
      metadata || {},
      methodDescriptor_PaperService_List,
      callback);
};


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Papers>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/List',
      request,
      metadata || {},
      methodDescriptor_PaperService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.SearchPaper,
 *   !proto.pbs.Papers>}
 */
const methodDescriptor_PaperService_Search = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/Search',
  grpc.web.MethodType.UNARY,
  proto.pbs.SearchPaper,
  proto.pbs.Papers,
  /**
   * @param {!proto.pbs.SearchPaper} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Papers.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.SearchPaper,
 *   !proto.pbs.Papers>}
 */
const methodInfo_PaperService_Search = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Papers,
  /**
   * @param {!proto.pbs.SearchPaper} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Papers.deserializeBinary
);


/**
 * @param {!proto.pbs.SearchPaper} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Papers)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Papers>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.search =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/Search',
      request,
      metadata || {},
      methodDescriptor_PaperService_Search,
      callback);
};


/**
 * @param {!proto.pbs.SearchPaper} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Papers>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.search =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/Search',
      request,
      metadata || {},
      methodDescriptor_PaperService_Search);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.CreatePaperForm,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_PaperService_Create = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/Create',
  grpc.web.MethodType.UNARY,
  proto.pbs.CreatePaperForm,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.CreatePaperForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.CreatePaperForm,
 *   !proto.pbs.Empty>}
 */
const methodInfo_PaperService_Create = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.CreatePaperForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.CreatePaperForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/Create',
      request,
      metadata || {},
      methodDescriptor_PaperService_Create,
      callback);
};


/**
 * @param {!proto.pbs.CreatePaperForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/Create',
      request,
      metadata || {},
      methodDescriptor_PaperService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Paper,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_PaperService_Delete = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/Delete',
  grpc.web.MethodType.UNARY,
  proto.pbs.Paper,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Paper} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Paper,
 *   !proto.pbs.Empty>}
 */
const methodInfo_PaperService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Paper} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Paper} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/Delete',
      request,
      metadata || {},
      methodDescriptor_PaperService_Delete,
      callback);
};


/**
 * @param {!proto.pbs.Paper} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/Delete',
      request,
      metadata || {},
      methodDescriptor_PaperService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.CreatePaperForm,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_PaperService_Edit = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/Edit',
  grpc.web.MethodType.UNARY,
  proto.pbs.CreatePaperForm,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.CreatePaperForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.CreatePaperForm,
 *   !proto.pbs.Empty>}
 */
const methodInfo_PaperService_Edit = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.CreatePaperForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.CreatePaperForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.edit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/Edit',
      request,
      metadata || {},
      methodDescriptor_PaperService_Edit,
      callback);
};


/**
 * @param {!proto.pbs.CreatePaperForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.edit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/Edit',
      request,
      metadata || {},
      methodDescriptor_PaperService_Edit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Paper,
 *   !proto.pbs.Paper>}
 */
const methodDescriptor_PaperService_View = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/View',
  grpc.web.MethodType.UNARY,
  proto.pbs.Paper,
  proto.pbs.Paper,
  /**
   * @param {!proto.pbs.Paper} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Paper.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Paper,
 *   !proto.pbs.Paper>}
 */
const methodInfo_PaperService_View = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Paper,
  /**
   * @param {!proto.pbs.Paper} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Paper.deserializeBinary
);


/**
 * @param {!proto.pbs.Paper} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Paper)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Paper>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.view =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/View',
      request,
      metadata || {},
      methodDescriptor_PaperService_View,
      callback);
};


/**
 * @param {!proto.pbs.Paper} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Paper>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.view =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/View',
      request,
      metadata || {},
      methodDescriptor_PaperService_View);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.QuestionCount,
 *   !proto.pbs.Scores>}
 */
const methodDescriptor_PaperService_getScores = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/getScores',
  grpc.web.MethodType.UNARY,
  proto.pbs.QuestionCount,
  proto.pbs.Scores,
  /**
   * @param {!proto.pbs.QuestionCount} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Scores.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.QuestionCount,
 *   !proto.pbs.Scores>}
 */
const methodInfo_PaperService_getScores = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Scores,
  /**
   * @param {!proto.pbs.QuestionCount} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Scores.deserializeBinary
);


/**
 * @param {!proto.pbs.QuestionCount} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Scores)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Scores>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.getScores =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/getScores',
      request,
      metadata || {},
      methodDescriptor_PaperService_getScores,
      callback);
};


/**
 * @param {!proto.pbs.QuestionCount} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Scores>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.getScores =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/getScores',
      request,
      metadata || {},
      methodDescriptor_PaperService_getScores);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.PaperListReq,
 *   !proto.pbs.Questions>}
 */
const methodDescriptor_PaperService_GetQuestions = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/GetQuestions',
  grpc.web.MethodType.UNARY,
  proto.pbs.PaperListReq,
  proto.pbs.Questions,
  /**
   * @param {!proto.pbs.PaperListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Questions.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.PaperListReq,
 *   !proto.pbs.Questions>}
 */
const methodInfo_PaperService_GetQuestions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Questions,
  /**
   * @param {!proto.pbs.PaperListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Questions.deserializeBinary
);


/**
 * @param {!proto.pbs.PaperListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Questions)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Questions>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.getQuestions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/GetQuestions',
      request,
      metadata || {},
      methodDescriptor_PaperService_GetQuestions,
      callback);
};


/**
 * @param {!proto.pbs.PaperListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Questions>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.getQuestions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/GetQuestions',
      request,
      metadata || {},
      methodDescriptor_PaperService_GetQuestions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Question,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_PaperService_AddQuestion = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/AddQuestion',
  grpc.web.MethodType.UNARY,
  proto.pbs.Question,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Question} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Question,
 *   !proto.pbs.Empty>}
 */
const methodInfo_PaperService_AddQuestion = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Question} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Question} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.addQuestion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/AddQuestion',
      request,
      metadata || {},
      methodDescriptor_PaperService_AddQuestion,
      callback);
};


/**
 * @param {!proto.pbs.Question} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.addQuestion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/AddQuestion',
      request,
      metadata || {},
      methodDescriptor_PaperService_AddQuestion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Question,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_PaperService_UpdateQuestion = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/UpdateQuestion',
  grpc.web.MethodType.UNARY,
  proto.pbs.Question,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Question} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Question,
 *   !proto.pbs.Empty>}
 */
const methodInfo_PaperService_UpdateQuestion = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Question} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Question} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.updateQuestion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/UpdateQuestion',
      request,
      metadata || {},
      methodDescriptor_PaperService_UpdateQuestion,
      callback);
};


/**
 * @param {!proto.pbs.Question} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.updateQuestion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/UpdateQuestion',
      request,
      metadata || {},
      methodDescriptor_PaperService_UpdateQuestion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.PaperListReq,
 *   !proto.pbs.Papers>}
 */
const methodDescriptor_PaperService_GetPapers = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/GetPapers',
  grpc.web.MethodType.UNARY,
  proto.pbs.PaperListReq,
  proto.pbs.Papers,
  /**
   * @param {!proto.pbs.PaperListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Papers.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.PaperListReq,
 *   !proto.pbs.Papers>}
 */
const methodInfo_PaperService_GetPapers = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Papers,
  /**
   * @param {!proto.pbs.PaperListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Papers.deserializeBinary
);


/**
 * @param {!proto.pbs.PaperListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Papers)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Papers>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.getPapers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/GetPapers',
      request,
      metadata || {},
      methodDescriptor_PaperService_GetPapers,
      callback);
};


/**
 * @param {!proto.pbs.PaperListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Papers>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.getPapers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/GetPapers',
      request,
      metadata || {},
      methodDescriptor_PaperService_GetPapers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.OperatePaperReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_PaperService_AddPaper = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/AddPaper',
  grpc.web.MethodType.UNARY,
  proto.pbs.OperatePaperReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.OperatePaperReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.OperatePaperReq,
 *   !proto.pbs.Empty>}
 */
const methodInfo_PaperService_AddPaper = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.OperatePaperReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.OperatePaperReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.addPaper =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/AddPaper',
      request,
      metadata || {},
      methodDescriptor_PaperService_AddPaper,
      callback);
};


/**
 * @param {!proto.pbs.OperatePaperReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.addPaper =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/AddPaper',
      request,
      metadata || {},
      methodDescriptor_PaperService_AddPaper);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.OperatePaperReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_PaperService_EditPaper = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/EditPaper',
  grpc.web.MethodType.UNARY,
  proto.pbs.OperatePaperReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.OperatePaperReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.OperatePaperReq,
 *   !proto.pbs.Empty>}
 */
const methodInfo_PaperService_EditPaper = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.OperatePaperReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.OperatePaperReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.editPaper =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/EditPaper',
      request,
      metadata || {},
      methodDescriptor_PaperService_EditPaper,
      callback);
};


/**
 * @param {!proto.pbs.OperatePaperReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.editPaper =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/EditPaper',
      request,
      metadata || {},
      methodDescriptor_PaperService_EditPaper);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Ids,
 *   !proto.pbs.Papers>}
 */
const methodDescriptor_PaperService_GetPapersByMajorIds = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/GetPapersByMajorIds',
  grpc.web.MethodType.UNARY,
  common_pb.Ids,
  proto.pbs.Papers,
  /**
   * @param {!proto.pbs.Ids} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Papers.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Ids,
 *   !proto.pbs.Papers>}
 */
const methodInfo_PaperService_GetPapersByMajorIds = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Papers,
  /**
   * @param {!proto.pbs.Ids} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Papers.deserializeBinary
);


/**
 * @param {!proto.pbs.Ids} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Papers)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Papers>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.getPapersByMajorIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/GetPapersByMajorIds',
      request,
      metadata || {},
      methodDescriptor_PaperService_GetPapersByMajorIds,
      callback);
};


/**
 * @param {!proto.pbs.Ids} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Papers>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.getPapersByMajorIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/GetPapersByMajorIds',
      request,
      metadata || {},
      methodDescriptor_PaperService_GetPapersByMajorIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.BatchImportQuestionReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_PaperService_BatchImportQuestion = new grpc.web.MethodDescriptor(
  '/pbs.PaperService/BatchImportQuestion',
  grpc.web.MethodType.UNARY,
  proto.pbs.BatchImportQuestionReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.BatchImportQuestionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.BatchImportQuestionReq,
 *   !proto.pbs.Empty>}
 */
const methodInfo_PaperService_BatchImportQuestion = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.BatchImportQuestionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.BatchImportQuestionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PaperServiceClient.prototype.batchImportQuestion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PaperService/BatchImportQuestion',
      request,
      metadata || {},
      methodDescriptor_PaperService_BatchImportQuestion,
      callback);
};


/**
 * @param {!proto.pbs.BatchImportQuestionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.PaperServicePromiseClient.prototype.batchImportQuestion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PaperService/BatchImportQuestion',
      request,
      metadata || {},
      methodDescriptor_PaperService_BatchImportQuestion);
};


module.exports = proto.pbs;

