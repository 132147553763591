import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ThemeModule } from './theme/theme.module';
import { RoutesModule } from './routes/routes.module';
import { AppComponent } from './app.component';

import { DefaultInterceptor,StartupService } from '@core';
export function StartupServiceFactory(startupService: StartupService) {
  return () => startupService.load();
}

import { FormlyModule } from '@ngx-formly/core';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
//import { MatNativeDateModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';

/**
 * - Bootstrap:     import {FormlyBootstrapModule} from '@ngx-formly/bootstrap';
 * - Material2:     import {FormlyMaterialModule} from '@ngx-formly/material';
 * - Ionic:         import {FormlyIonicModule} from '@ngx-formly/ionic'
 * - PrimeNG:       import {FormlyPrimeNGModule} from '@ngx-formly/primeng';
 * - Kendo:         import {FormlyKendoModule} from '@ngx-formly/kendo';
 * - NativeScript:  import {FormlyNativescriptModule} from '@ngx-formly/nativescript';
 */
import {FormlyBootstrapModule} from '@ngx-formly/bootstrap';

@NgModule({
  declarations: [AppComponent],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    ThemeModule,
    RoutesModule,
    FormlyModule.forRoot(),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    //MatNativeDateModule,
    //FormlyMatDatepickerModule,
    FormlyBootstrapModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
    StartupService,
    {
      provide: APP_INITIALIZER,
      useFactory: StartupServiceFactory,
      deps: [StartupService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
