import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '@core/services/user.service';
import {LoginUser,User} from '@core/pbs/user_pb';
//import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  reactiveForm: FormGroup;
  verCode:string;
  cureentTime:number

  constructor(private fb: FormBuilder, private router: Router,private userService:UserService,private toastr: ToastrService) {
    this.reactiveForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      code: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    //获取验证码
    this.getVerifyCode()
  }

  getVerifyCode() {
    //获取验证码
    this.userService.getVerifyCode((res:LoginUser) =>{
      this.verCode = res.getImgcode()
    },err=>{
      this.toastr.error(err,'获取验证码失败')
    })
  }

  login() {
    this.userService.login(this.reactiveForm.value.username,this.reactiveForm.value.password,this.reactiveForm.value.code,(res:User)=>{
      this.router.navigateByUrl('/');//跳转首页
    },(err:string)=>{
      this.toastr.error(err,'登录失败')
      this.getVerifyCode()
    })

    //
  }
}
