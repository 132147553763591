/**
 * @fileoverview gRPC-Web generated client stub for pbs
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var common_pb = require('./common_pb.js')

var class_pb = require('./class_pb.js')
const proto = {};
proto.pbs = require('./user_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.UserServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.UserServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Empty,
 *   !proto.pbs.Users>}
 */
const methodDescriptor_UserService_List = new grpc.web.MethodDescriptor(
  '/pbs.UserService/List',
  grpc.web.MethodType.UNARY,
  common_pb.Empty,
  proto.pbs.Users,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Users.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Empty,
 *   !proto.pbs.Users>}
 */
const methodInfo_UserService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Users,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Users.deserializeBinary
);


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Users)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Users>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/List',
      request,
      metadata || {},
      methodDescriptor_UserService_List,
      callback);
};


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Users>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/List',
      request,
      metadata || {},
      methodDescriptor_UserService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.LoginUser,
 *   !proto.pbs.User>}
 */
const methodDescriptor_UserService_Login = new grpc.web.MethodDescriptor(
  '/pbs.UserService/Login',
  grpc.web.MethodType.UNARY,
  proto.pbs.LoginUser,
  proto.pbs.User,
  /**
   * @param {!proto.pbs.LoginUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.User.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.LoginUser,
 *   !proto.pbs.User>}
 */
const methodInfo_UserService_Login = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.User,
  /**
   * @param {!proto.pbs.LoginUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.User.deserializeBinary
);


/**
 * @param {!proto.pbs.LoginUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/Login',
      request,
      metadata || {},
      methodDescriptor_UserService_Login,
      callback);
};


/**
 * @param {!proto.pbs.LoginUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.User>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/Login',
      request,
      metadata || {},
      methodDescriptor_UserService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.LoginUser,
 *   !proto.pbs.User>}
 */
const methodDescriptor_UserService_AdminLogin = new grpc.web.MethodDescriptor(
  '/pbs.UserService/AdminLogin',
  grpc.web.MethodType.UNARY,
  proto.pbs.LoginUser,
  proto.pbs.User,
  /**
   * @param {!proto.pbs.LoginUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.User.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.LoginUser,
 *   !proto.pbs.User>}
 */
const methodInfo_UserService_AdminLogin = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.User,
  /**
   * @param {!proto.pbs.LoginUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.User.deserializeBinary
);


/**
 * @param {!proto.pbs.LoginUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.adminLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/AdminLogin',
      request,
      metadata || {},
      methodDescriptor_UserService_AdminLogin,
      callback);
};


/**
 * @param {!proto.pbs.LoginUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.User>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.adminLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/AdminLogin',
      request,
      metadata || {},
      methodDescriptor_UserService_AdminLogin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ChangePwdForm,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_ChangePwd = new grpc.web.MethodDescriptor(
  '/pbs.UserService/ChangePwd',
  grpc.web.MethodType.UNARY,
  proto.pbs.ChangePwdForm,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.ChangePwdForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.ChangePwdForm,
 *   !proto.pbs.Empty>}
 */
const methodInfo_UserService_ChangePwd = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.ChangePwdForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.ChangePwdForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.changePwd =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/ChangePwd',
      request,
      metadata || {},
      methodDescriptor_UserService_ChangePwd,
      callback);
};


/**
 * @param {!proto.pbs.ChangePwdForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.changePwd =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/ChangePwd',
      request,
      metadata || {},
      methodDescriptor_UserService_ChangePwd);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.User,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_Create = new grpc.web.MethodDescriptor(
  '/pbs.UserService/Create',
  grpc.web.MethodType.UNARY,
  proto.pbs.User,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.User,
 *   !proto.pbs.Empty>}
 */
const methodInfo_UserService_Create = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/Create',
      request,
      metadata || {},
      methodDescriptor_UserService_Create,
      callback);
};


/**
 * @param {!proto.pbs.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/Create',
      request,
      metadata || {},
      methodDescriptor_UserService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.User,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_Delete = new grpc.web.MethodDescriptor(
  '/pbs.UserService/Delete',
  grpc.web.MethodType.UNARY,
  proto.pbs.User,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.User,
 *   !proto.pbs.Empty>}
 */
const methodInfo_UserService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/Delete',
      request,
      metadata || {},
      methodDescriptor_UserService_Delete,
      callback);
};


/**
 * @param {!proto.pbs.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/Delete',
      request,
      metadata || {},
      methodDescriptor_UserService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.UserForm,
 *   !proto.pbs.User>}
 */
const methodDescriptor_UserService_Edit = new grpc.web.MethodDescriptor(
  '/pbs.UserService/Edit',
  grpc.web.MethodType.UNARY,
  proto.pbs.UserForm,
  proto.pbs.User,
  /**
   * @param {!proto.pbs.UserForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.User.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.UserForm,
 *   !proto.pbs.User>}
 */
const methodInfo_UserService_Edit = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.User,
  /**
   * @param {!proto.pbs.UserForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.User.deserializeBinary
);


/**
 * @param {!proto.pbs.UserForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.edit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/Edit',
      request,
      metadata || {},
      methodDescriptor_UserService_Edit,
      callback);
};


/**
 * @param {!proto.pbs.UserForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.User>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.edit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/Edit',
      request,
      metadata || {},
      methodDescriptor_UserService_Edit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.User,
 *   !proto.pbs.User>}
 */
const methodDescriptor_UserService_View = new grpc.web.MethodDescriptor(
  '/pbs.UserService/View',
  grpc.web.MethodType.UNARY,
  proto.pbs.User,
  proto.pbs.User,
  /**
   * @param {!proto.pbs.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.User.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.User,
 *   !proto.pbs.User>}
 */
const methodInfo_UserService_View = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.User,
  /**
   * @param {!proto.pbs.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.User.deserializeBinary
);


/**
 * @param {!proto.pbs.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.view =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/View',
      request,
      metadata || {},
      methodDescriptor_UserService_View,
      callback);
};


/**
 * @param {!proto.pbs.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.User>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.view =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/View',
      request,
      metadata || {},
      methodDescriptor_UserService_View);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Empty,
 *   !proto.pbs.LoginUser>}
 */
const methodDescriptor_UserService_GetCode = new grpc.web.MethodDescriptor(
  '/pbs.UserService/GetCode',
  grpc.web.MethodType.UNARY,
  common_pb.Empty,
  proto.pbs.LoginUser,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.LoginUser.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Empty,
 *   !proto.pbs.LoginUser>}
 */
const methodInfo_UserService_GetCode = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.LoginUser,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.LoginUser.deserializeBinary
);


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.LoginUser)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.LoginUser>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.getCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/GetCode',
      request,
      metadata || {},
      methodDescriptor_UserService_GetCode,
      callback);
};


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.LoginUser>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.getCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/GetCode',
      request,
      metadata || {},
      methodDescriptor_UserService_GetCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.SmsCodeParam,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_GetCodeSms = new grpc.web.MethodDescriptor(
  '/pbs.UserService/GetCodeSms',
  grpc.web.MethodType.UNARY,
  proto.pbs.SmsCodeParam,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.SmsCodeParam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.SmsCodeParam,
 *   !proto.pbs.Empty>}
 */
const methodInfo_UserService_GetCodeSms = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.SmsCodeParam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.SmsCodeParam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.getCodeSms =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/GetCodeSms',
      request,
      metadata || {},
      methodDescriptor_UserService_GetCodeSms,
      callback);
};


/**
 * @param {!proto.pbs.SmsCodeParam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.getCodeSms =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/GetCodeSms',
      request,
      metadata || {},
      methodDescriptor_UserService_GetCodeSms);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ChangePwdForm,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_CheckOldPwd = new grpc.web.MethodDescriptor(
  '/pbs.UserService/CheckOldPwd',
  grpc.web.MethodType.UNARY,
  proto.pbs.ChangePwdForm,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.ChangePwdForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.ChangePwdForm,
 *   !proto.pbs.Empty>}
 */
const methodInfo_UserService_CheckOldPwd = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.ChangePwdForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.ChangePwdForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.checkOldPwd =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/CheckOldPwd',
      request,
      metadata || {},
      methodDescriptor_UserService_CheckOldPwd,
      callback);
};


/**
 * @param {!proto.pbs.ChangePwdForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.checkOldPwd =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/CheckOldPwd',
      request,
      metadata || {},
      methodDescriptor_UserService_CheckOldPwd);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.UserSearchForm,
 *   !proto.pbs.Users>}
 */
const methodDescriptor_UserService_Search = new grpc.web.MethodDescriptor(
  '/pbs.UserService/Search',
  grpc.web.MethodType.UNARY,
  proto.pbs.UserSearchForm,
  proto.pbs.Users,
  /**
   * @param {!proto.pbs.UserSearchForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Users.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.UserSearchForm,
 *   !proto.pbs.Users>}
 */
const methodInfo_UserService_Search = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Users,
  /**
   * @param {!proto.pbs.UserSearchForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Users.deserializeBinary
);


/**
 * @param {!proto.pbs.UserSearchForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Users)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Users>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.search =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/Search',
      request,
      metadata || {},
      methodDescriptor_UserService_Search,
      callback);
};


/**
 * @param {!proto.pbs.UserSearchForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Users>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.search =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/Search',
      request,
      metadata || {},
      methodDescriptor_UserService_Search);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.UserListReq,
 *   !proto.pbs.Users>}
 */
const methodDescriptor_UserService_UserList = new grpc.web.MethodDescriptor(
  '/pbs.UserService/UserList',
  grpc.web.MethodType.UNARY,
  proto.pbs.UserListReq,
  proto.pbs.Users,
  /**
   * @param {!proto.pbs.UserListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Users.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.UserListReq,
 *   !proto.pbs.Users>}
 */
const methodInfo_UserService_UserList = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Users,
  /**
   * @param {!proto.pbs.UserListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Users.deserializeBinary
);


/**
 * @param {!proto.pbs.UserListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Users)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Users>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.userList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/UserList',
      request,
      metadata || {},
      methodDescriptor_UserService_UserList,
      callback);
};


/**
 * @param {!proto.pbs.UserListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Users>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.userList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/UserList',
      request,
      metadata || {},
      methodDescriptor_UserService_UserList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.User,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_CreateUser = new grpc.web.MethodDescriptor(
  '/pbs.UserService/CreateUser',
  grpc.web.MethodType.UNARY,
  proto.pbs.User,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.User,
 *   !proto.pbs.Empty>}
 */
const methodInfo_UserService_CreateUser = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.createUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/CreateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_CreateUser,
      callback);
};


/**
 * @param {!proto.pbs.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.createUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/CreateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_CreateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.RegisterReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_Register = new grpc.web.MethodDescriptor(
  '/pbs.UserService/Register',
  grpc.web.MethodType.UNARY,
  proto.pbs.RegisterReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.RegisterReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.RegisterReq,
 *   !proto.pbs.Empty>}
 */
const methodInfo_UserService_Register = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.RegisterReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.RegisterReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.register =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/Register',
      request,
      metadata || {},
      methodDescriptor_UserService_Register,
      callback);
};


/**
 * @param {!proto.pbs.RegisterReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.register =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/Register',
      request,
      metadata || {},
      methodDescriptor_UserService_Register);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ExamId,
 *   !proto.pbs.User>}
 */
const methodDescriptor_UserService_GetUserByExamId = new grpc.web.MethodDescriptor(
  '/pbs.UserService/GetUserByExamId',
  grpc.web.MethodType.UNARY,
  proto.pbs.ExamId,
  proto.pbs.User,
  /**
   * @param {!proto.pbs.ExamId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.User.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.ExamId,
 *   !proto.pbs.User>}
 */
const methodInfo_UserService_GetUserByExamId = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.User,
  /**
   * @param {!proto.pbs.ExamId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.User.deserializeBinary
);


/**
 * @param {!proto.pbs.ExamId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.getUserByExamId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/GetUserByExamId',
      request,
      metadata || {},
      methodDescriptor_UserService_GetUserByExamId,
      callback);
};


/**
 * @param {!proto.pbs.ExamId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.User>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.getUserByExamId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/GetUserByExamId',
      request,
      metadata || {},
      methodDescriptor_UserService_GetUserByExamId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Empty,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_CheckTokenIsValid = new grpc.web.MethodDescriptor(
  '/pbs.UserService/CheckTokenIsValid',
  grpc.web.MethodType.UNARY,
  common_pb.Empty,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Empty,
 *   !proto.pbs.Empty>}
 */
const methodInfo_UserService_CheckTokenIsValid = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.checkTokenIsValid =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/CheckTokenIsValid',
      request,
      metadata || {},
      methodDescriptor_UserService_CheckTokenIsValid,
      callback);
};


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.checkTokenIsValid =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/CheckTokenIsValid',
      request,
      metadata || {},
      methodDescriptor_UserService_CheckTokenIsValid);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.User,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_NewCreateUser = new grpc.web.MethodDescriptor(
  '/pbs.UserService/NewCreateUser',
  grpc.web.MethodType.UNARY,
  proto.pbs.User,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.User,
 *   !proto.pbs.Empty>}
 */
const methodInfo_UserService_NewCreateUser = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.newCreateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/NewCreateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_NewCreateUser,
      callback);
};


/**
 * @param {!proto.pbs.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.newCreateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/NewCreateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_NewCreateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.User,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_EditUser = new grpc.web.MethodDescriptor(
  '/pbs.UserService/EditUser',
  grpc.web.MethodType.UNARY,
  proto.pbs.User,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.User,
 *   !proto.pbs.Empty>}
 */
const methodInfo_UserService_EditUser = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.editUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/EditUser',
      request,
      metadata || {},
      methodDescriptor_UserService_EditUser,
      callback);
};


/**
 * @param {!proto.pbs.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.editUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/EditUser',
      request,
      metadata || {},
      methodDescriptor_UserService_EditUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.BatchImportReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_BatchImport = new grpc.web.MethodDescriptor(
  '/pbs.UserService/BatchImport',
  grpc.web.MethodType.UNARY,
  proto.pbs.BatchImportReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.BatchImportReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.BatchImportReq,
 *   !proto.pbs.Empty>}
 */
const methodInfo_UserService_BatchImport = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.BatchImportReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.BatchImportReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.batchImport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/BatchImport',
      request,
      metadata || {},
      methodDescriptor_UserService_BatchImport,
      callback);
};


/**
 * @param {!proto.pbs.BatchImportReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.batchImport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/BatchImport',
      request,
      metadata || {},
      methodDescriptor_UserService_BatchImport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Empty,
 *   !proto.pbs.LoginNums>}
 */
const methodDescriptor_UserService_GetClientLoginNum = new grpc.web.MethodDescriptor(
  '/pbs.UserService/GetClientLoginNum',
  grpc.web.MethodType.UNARY,
  common_pb.Empty,
  proto.pbs.LoginNums,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.LoginNums.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Empty,
 *   !proto.pbs.LoginNums>}
 */
const methodInfo_UserService_GetClientLoginNum = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.LoginNums,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.LoginNums.deserializeBinary
);


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.LoginNums)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.LoginNums>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.getClientLoginNum =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/GetClientLoginNum',
      request,
      metadata || {},
      methodDescriptor_UserService_GetClientLoginNum,
      callback);
};


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.LoginNums>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.getClientLoginNum =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/GetClientLoginNum',
      request,
      metadata || {},
      methodDescriptor_UserService_GetClientLoginNum);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.UpdateUserSchoolReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_UpdateUserSchool = new grpc.web.MethodDescriptor(
  '/pbs.UserService/UpdateUserSchool',
  grpc.web.MethodType.UNARY,
  proto.pbs.UpdateUserSchoolReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.UpdateUserSchoolReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.UpdateUserSchoolReq,
 *   !proto.pbs.Empty>}
 */
const methodInfo_UserService_UpdateUserSchool = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.UpdateUserSchoolReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.UpdateUserSchoolReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.updateUserSchool =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/UpdateUserSchool',
      request,
      metadata || {},
      methodDescriptor_UserService_UpdateUserSchool,
      callback);
};


/**
 * @param {!proto.pbs.UpdateUserSchoolReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.updateUserSchool =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/UpdateUserSchool',
      request,
      metadata || {},
      methodDescriptor_UserService_UpdateUserSchool);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Empty,
 *   !proto.pbs.User>}
 */
const methodDescriptor_UserService_GetUserInfo = new grpc.web.MethodDescriptor(
  '/pbs.UserService/GetUserInfo',
  grpc.web.MethodType.UNARY,
  common_pb.Empty,
  proto.pbs.User,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.User.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Empty,
 *   !proto.pbs.User>}
 */
const methodInfo_UserService_GetUserInfo = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.User,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.User.deserializeBinary
);


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.getUserInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/GetUserInfo',
      request,
      metadata || {},
      methodDescriptor_UserService_GetUserInfo,
      callback);
};


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.User>}
 *     A native promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.getUserInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/GetUserInfo',
      request,
      metadata || {},
      methodDescriptor_UserService_GetUserInfo);
};


module.exports = proto.pbs;

