import { Component,Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-user-panel',
  template: `
    <div
      class="matero-user-panel p-y-16 b-t-1 b-b-1"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <img
        class="matero-user-panel-avatar m-b-8 r-full"
        src="{{avatar}}"
        alt="avatar"
        width="64"
      />
      <h4 class="matero-user-panel-name m-t-0 m-b-8 f-w-400">{{fname}}</h4>
      <h5 class="matero-user-panel-email m-t-0 m-b-8 f-w-400">{{email}}</h5>
      <div class="matero-user-panel-icons text-nowrap">
        <a routerLink="/profile/overview" mat-icon-button>
          <mat-icon class="icon-18">account_circle</mat-icon>
        </a>
        <a routerLink="/profile/settings" mat-icon-button>
          <mat-icon class="icon-18">settings</mat-icon>
        </a>
        <a routerLink="###" (click)="logout()" mat-icon-button>
          <mat-icon class="icon-18">exit_to_app</mat-icon>
        </a>
      </div>
    </div>
  `,
})
export class UserPanelComponent {
    @Input() profile:any;
    fname:string
    avatar:string
    email:string


    constructor(private router: Router) {
    }

    logout() {
       ngEvents.trigger('logout');
    }

    ngOnInit() {
      if (this.profile == null) {
        return
      }
      this.fname = this.profile.userfname
      this.avatar = environment.domainName + this.profile.useravatar
      this.email = this.profile.useremail
    }
}
