import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Service } from '@core/services/service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@core/services/user.service';
import { LoginNums } from '@core/pbs/user_pb';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
  public loginNum = 0;
  user: any;
  constructor(
    private cdr: ChangeDetectorRef,
    private service:UserService,
    private toastr: ToastrService,
    private router: Router) {}

  ngOnInit() {
    this.user = this.service.getUser().toObject(true);
    if (this.user.usertype == 2) { //系统管理员
      this.getClientLoginNum()
    } else {
      let token = this.service.getToken()
      if (token == "") {
        this.router.navigateByUrl('/auth/login');//跳转登录
      }
    }
  }

  getClientLoginNum() {
    this.service.getClientLoginNum((data:LoginNums)=>{
      this.loginNum = data.getNum()
    },(err:string)=>{
      this.toastr.error(decodeURI(err),'获取数据失败')
    })
  }
}
