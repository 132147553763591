import { Injectable } from '@angular/core';
import {User} from '../pbs/user_pb';
import {Md5} from "ts-md5";
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
/**
 * @class Service
 * @desc RPC服务基类
 * @author Chen Meiujun
 * @date 2019-09-12
 */
export class Service {
  //protected url = 'http://10.0.0.55:8089';
	//protected url = 'http://58.246.129.34:8082'; //rpc服务端地址
	//protected url = 'http://localhost:8081'; //rpc服务端地址
  //protected url = 'http://47.103.15.129:81'
  // protected url = 'http://10.0.0.55:8089';
   protected url = 'https://webrpc.vsteach.com'
	//protected url = 'http://rpc.stage.vsteach.com'

	protected metadata = {'session':'','token':'','platform':'admin'} //metadata信息
  private router: Router
	constructor() {
		this.metadata.session = this.session()
    this.initToken()
    //监听登录事件
    ngEvents.on('login',  ()=> {
        this.initToken()
    });
	}

	session() {
		let sess = sessionStorage.getItem('session')
		if (!sess) {
			sess = String(Md5.hashStr(new Date().getTime().toLocaleString()));
			sessionStorage.setItem('session',sess)
		}
		return sess
	}


	/**
	 * 初始化token对象
	 */
	initToken() {
		this.metadata.token = this.getToken()
	}

	/**
	 * 保存登录用户
	 */
	saveUser(user:User) {
    let strData = this.Uint8ArrayToString(user.serializeBinary())
		 localStorage.setItem('userInfo', strData);
     this.initToken()
	}

  Uint8ArrayToString(fileData){
    var dataString = "";
    for (var i = 0; i < fileData.length; i++) {
      dataString += String.fromCharCode(fileData[i]);
    }
    return dataString
  }

  stringToUint8Array(str){
    var arr = [];
    for (var i = 0, j = str.length; i < j; ++i) {
      arr.push(str.charCodeAt(i));
    }

    var tmpUint8Array = new Uint8Array(arr);
    return tmpUint8Array
  }

	/**
	 * 获取已登录用户
	 */
	getUser():User{
		let userStr = localStorage.getItem('userInfo');
    if (!userStr) {
      return  new User();
    }
    let u8Data = this.stringToUint8Array(userStr)
    let u = User.deserializeBinary(u8Data)
		return  u;
	}

	/**
	 * 删除用户数据
	 */
	removeUser() {
		if (localStorage.hasOwnProperty("userInfo")) {
			localStorage.removeItem('userInfo');
		}
	}

	/**
	 * 获取已登录用户token
	 */
	getToken():string{
		let user = this.getUser();
		if (user) {
			return user.getUsertoken();
		}
		return ''
	}



  /**
   * rpc请求
   */
  request(obj:any,method:string, params:any, success:Function,fail:Function=null) {
    let called = false;//已经回调过了
    if (!obj[method])
      return

    obj[method](params,
  			this.metadata, (err: Error, res:any)=> {

            if (called) {
              return
            }
            called = true

  				  if (!err && success != null) {
  					  success(res) //成功回调 返回用户对象
              return
  				  }

  				  if (err && fail != null) {
              let msg = decodeURI(err.message)
              if (msg == '未登录') {
                 ngEvents.trigger('logout');//登出
              }

              if (msg == 'token无效') {
                  ngEvents.trigger('logout');//登出
              }
  						fail(msg) //失败回调 直接传输错误原因string类型接收即可
  				  }
  			});
  	}

    //客户端流式rpc
    requestStream(obj:any,method:string, params:any) {
      console.log("obj", obj)
      if (!obj[method])
        return

      var stream = obj[method](params, this.metadata)
      console.log("zza222", stream)
      stream.on('data', function(response) {
        console.log("data", response);
      });
      stream.on('status', function(status) {
        console.log("status", status.code);
        console.log(status.details);
        console.log(status.metadata);
      });
      stream.on('end', function(end) {
        console.log("end", end);
      });
    }

    setData(data:object,setdata:object) {
      if (data != null) {
          for (let k in setdata) {
             let key = 'set'+this.UpperFirst(k)
             let val = setdata[k]
             if (key in data) {
               let func = data[key]
               if (typeof(val) != 'object' && (func instanceof Function)) {
                 data[key](val)
               }

             }
          }
      }
    }


    UpperFirst(word:string):string {
      let words = word.split('')
      if (words.length > 0) {
          words[0] = words[0].toUpperCase()
      }
      return words.join('')
    }
}
