import { Injectable } from '@angular/core';
import { Service } from './service';
import { PaperServiceClient } from '../pbs/paper_grpc_web_pb';
import { Questions,Papers,Question,Paper,SearchPaper,QuestionCount, CreatePaperForm, QuestionSearchForm, PaperListReq, OperatePaperReq, Ids } from '../pbs/paper_pb';
import { Major } from '../pbs/major_pb';
import { Empty } from '../pbs/common_pb';

@Injectable({
  providedIn: 'root'
})
export class PaperService extends Service {

  client: PaperServiceClient;

  constructor() {
    super()
    this.client = new PaperServiceClient(this.url,null,null);
  }


  /**
   * @description 获取试题列表
   * @author Megan
   * @date 2019-09-15
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof PaperService
   */
  getQuestionList(callback:Function=null,fail:Function=null) {
    this.client.listQuestion(new Empty(),
      null, (err:Error, response: Questions) => {
        if (!err && callback != null) {
          callback(response);
        }

        if (err && fail != null) {
          fail(err.message);
        }
      }
    )
  }

  /**
   * @description 查看试题
   * @author Megan
   * @date 2019-09-15
   * @param {String} questionid
   * @param {Function} callback
   * @param {Function} [fail=null]
   * @memberof PaperService
   */
  viewQuestion(questionid:String,callback:Function,fail:Function=null) {
    let question = new Question();
    question.setQuestionId(questionid);
    this.client.viewQuestion(question,
      this.metadata, (err: Error, response: Question) => {
          if (!err && callback != null) {
            callback(response)
          }

          if (err && fail != null) {
            fail(err.message)
          }
        }
      )
  }




  /**
   * @description 获取试卷列表
   * @author Megan
   * @date 2019-09-15
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof PaperService
   */
  getPaperList(callback:Function=null,fail:Function=null) {
    this.client.list(new Empty(),
    this.metadata, (err:Error, response: Papers) => {
        if (!err && callback != null) {
          callback(response);
        }

        if (err && fail != null) {
          fail(err.message);
        }
      }
    )
  }

  /** 查看试卷
   * @description
   * @author Megan
   * @date 2019-09-15
   * @param {String} paperid
   * @param {Function} callback
   * @param {Function} [fail=null]
   * @memberof PaperService
   */
  viewPaper(paperid:String,callback:Function,fail:Function=null) {
      let paper = new Paper();
      paper.setPaperId(paperid);
      this.client.view(paper,
        this.metadata, (err: Error, response: Paper) => {
            if (!err && callback != null) {
              callback(response)
            }

            if (err && fail != null) {
              fail(err.message)
            }
          }
        )
  }


  /**
   * @description 修改试卷
   * @author Megan
   * @date 2019-09-15
   * @param {string} param
   * @param {Function} callback
   * @param {Function} [fail=null]
   * @memberof PaperService
   */
  editPaper(param: string,callback:Function,fail:Function=null) {
    let pa = JSON.parse(param);
    let paper = new Paper(pa);
    this.client.edit(paper,
      this.metadata, (err: Error, response: Paper) => {
          if (!err && callback != null) {
            callback(response)
          }

          if (err && fail != null) {
            fail(err.message)
          }
        }
      )
  }




  /**
   * @description 搜索试卷列表
   * @author Meijun
   * @date 2019-10-10
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof PlaceService
   */
  public search(filter:SearchPaper,callback:Function=null,fail:Function=null) {
    this.request(this.client,'search',filter,callback,fail)
  }

  /**
   * @description 获取试卷列表
   * @author Meijun
   * @date 2019-09-15
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof PlaceService
   */
  public list(callback:Function=null,fail:Function=null) {
    this.request(this.client,'list',new Empty(),callback,fail)
  }


  /**
   * @description 删除
   * @author ChenMeijun
   * @date 2019-09-27
   * @param {Paper} m 试卷object
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof PaperService
   */
  public delete(m:Paper,callback:Function=null,fail:Function=null) {
    this.request(this.client,'delete',m,callback,fail)
  }

  /**
   * @description 编辑
   * @author ChenMeijun
   * @date 2019-09-30
   * @param {Paper} m 试卷object
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof PaperService
   */
  public edit(m:CreatePaperForm,callback:Function=null,fail:Function=null) {
    this.request(this.client,'edit',m,callback,fail)
  }

  /**
   * @description 创建试卷
   * @author ChenMeijun
   * @date 2019-09-30
   * @param {Paper} m 试卷object
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof PaperService
   */
  public create(m:CreatePaperForm,callback:Function=null,fail:Function=null) {
    this.request(this.client,'create',m,callback,fail)
  }

  public getScores(m:QuestionCount,callback:Function=null,fail:Function=null) {
	  this.request(this.client,'getScores',m,callback,fail)
  }

  /**
   * @description 获取试题列表
   * @author Meijun
   * @date 2019-09-15
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof PaperService
   */
  public listQuestion(callback:Function=null,fail:Function=null) {
    this.request(this.client,'listQuestion',new Empty(),callback,fail)
  }


  /**
   * @description 根据专业获取试题列表
   * @author Meijun
   * @date 2019-09-15
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof PaperService
   */
  public listQuestionByMajor(m:QuestionSearchForm,callback:Function=null,fail:Function=null) {
    this.request(this.client,'listQuestionByMajor',m,callback,fail)
  }


  /**
   * @description 删除
   * @author ChenMeijun
   * @date 2019-09-27
   * @param {Question} m 试题object
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof PaperService
   */
  public deleteQuestion(m:Question,callback:Function=null,fail:Function=null) {
    this.request(this.client,'deleteQuestion',m,callback,fail)
  }

  /**
   * @description 编辑
   * @author ChenMeijun
   * @date 2019-09-30
   * @param {Question} m 试题object
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof PaperService
   */
  public editQuestion(m:Question,callback:Function=null,fail:Function=null) {
    this.request(this.client,'editQuestion',m,callback,fail)
  }

  /**
   * @description 创建试题
   * @author ChenMeijun
   * @date 2019-09-30
   * @param {Question} m 试题object
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof PaperService
   */
  public createQuestion(m:Question,callback:Function=null,fail:Function=null) {
    this.request(this.client,'createQuestion',m,callback,fail)
  }

  getQuestions(req:PaperListReq,callback:Function=null,fail:Function=null) {
    this.request(this.client,'getQuestions',req,callback,fail)
  }

  addQuestion(req:Question,callback:Function=null,fail:Function=null) {
    this.request(this.client,'addQuestion',req,callback,fail)
  }

  getPapers(req:PaperListReq,callback:Function=null,fail:Function=null) {
    this.request(this.client,'getPapers',req,callback,fail)
  }
  
  addPaper(req:OperatePaperReq,callback:Function=null,fail:Function=null) {
    this.request(this.client,'addPaper',req,callback,fail)
  }
  
  updatePaper(req:OperatePaperReq,callback:Function=null,fail:Function=null) {
    this.request(this.client,'editPaper',req,callback,fail)
  }
  
  getPapersByMajorIds(req:Ids,callback:Function=null,fail:Function=null) {
    this.request(this.client,'getPapersByMajorIds',req,callback,fail)
  }
}
