// source: paper.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var user_pb = require('./user_pb.js');
goog.object.extend(proto, user_pb);
var major_pb = require('./major_pb.js');
goog.object.extend(proto, major_pb);
var cutter_pb = require('./cutter_pb.js');
goog.object.extend(proto, cutter_pb);
var blank_pb = require('./blank_pb.js');
goog.object.extend(proto, blank_pb);
var score_standard_pb = require('./score_standard_pb.js');
goog.object.extend(proto, score_standard_pb);
goog.exportSymbol('proto.pbs.BatchImportQuestionReq', null, global);
goog.exportSymbol('proto.pbs.CraftOrderList', null, global);
goog.exportSymbol('proto.pbs.CreatePaperForm', null, global);
goog.exportSymbol('proto.pbs.OperatePaperReq', null, global);
goog.exportSymbol('proto.pbs.Paper', null, global);
goog.exportSymbol('proto.pbs.PaperListReq', null, global);
goog.exportSymbol('proto.pbs.Papers', null, global);
goog.exportSymbol('proto.pbs.Question', null, global);
goog.exportSymbol('proto.pbs.QuestionCount', null, global);
goog.exportSymbol('proto.pbs.QuestionSearchForm', null, global);
goog.exportSymbol('proto.pbs.Questions', null, global);
goog.exportSymbol('proto.pbs.Scores', null, global);
goog.exportSymbol('proto.pbs.SearchPaper', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.BatchImportQuestionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.BatchImportQuestionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.BatchImportQuestionReq.displayName = 'proto.pbs.BatchImportQuestionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PaperListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.PaperListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PaperListReq.displayName = 'proto.pbs.PaperListReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.OperatePaperReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.OperatePaperReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.OperatePaperReq.displayName = 'proto.pbs.OperatePaperReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CreatePaperForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.CreatePaperForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CreatePaperForm.displayName = 'proto.pbs.CreatePaperForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Scores = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.Scores, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Scores.displayName = 'proto.pbs.Scores';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.QuestionCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.QuestionCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.QuestionCount.displayName = 'proto.pbs.QuestionCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SearchPaper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SearchPaper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SearchPaper.displayName = 'proto.pbs.SearchPaper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Questions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.Questions.repeatedFields_, null);
};
goog.inherits(proto.pbs.Questions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Questions.displayName = 'proto.pbs.Questions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Papers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.Papers.repeatedFields_, null);
};
goog.inherits(proto.pbs.Papers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Papers.displayName = 'proto.pbs.Papers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.QuestionSearchForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.QuestionSearchForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.QuestionSearchForm.displayName = 'proto.pbs.QuestionSearchForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CraftOrderList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.CraftOrderList.repeatedFields_, null);
};
goog.inherits(proto.pbs.CraftOrderList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CraftOrderList.displayName = 'proto.pbs.CraftOrderList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Question = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.Question.repeatedFields_, null);
};
goog.inherits(proto.pbs.Question, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Question.displayName = 'proto.pbs.Question';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Paper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.Paper.repeatedFields_, null);
};
goog.inherits(proto.pbs.Paper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Paper.displayName = 'proto.pbs.Paper';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.BatchImportQuestionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.BatchImportQuestionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.BatchImportQuestionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.BatchImportQuestionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filePath: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.BatchImportQuestionReq}
 */
proto.pbs.BatchImportQuestionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.BatchImportQuestionReq;
  return proto.pbs.BatchImportQuestionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.BatchImportQuestionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.BatchImportQuestionReq}
 */
proto.pbs.BatchImportQuestionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilePath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.BatchImportQuestionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.BatchImportQuestionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.BatchImportQuestionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.BatchImportQuestionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilePath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string file_path = 1;
 * @return {string}
 */
proto.pbs.BatchImportQuestionReq.prototype.getFilePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.BatchImportQuestionReq} returns this
 */
proto.pbs.BatchImportQuestionReq.prototype.setFilePath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PaperListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PaperListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PaperListReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PaperListReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    schoolId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    facultyId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    majorId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    keyword: jspb.Message.getFieldWithDefault(msg, 4, ""),
    page: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 6, 0),
    questionType: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PaperListReq}
 */
proto.pbs.PaperListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PaperListReq;
  return proto.pbs.PaperListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PaperListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PaperListReq}
 */
proto.pbs.PaperListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuestionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PaperListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PaperListReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PaperListReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PaperListReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMajorId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getQuestionType();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string school_id = 1;
 * @return {string}
 */
proto.pbs.PaperListReq.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PaperListReq} returns this
 */
proto.pbs.PaperListReq.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string faculty_id = 2;
 * @return {string}
 */
proto.pbs.PaperListReq.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PaperListReq} returns this
 */
proto.pbs.PaperListReq.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string major_id = 3;
 * @return {string}
 */
proto.pbs.PaperListReq.prototype.getMajorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PaperListReq} returns this
 */
proto.pbs.PaperListReq.prototype.setMajorId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string keyword = 4;
 * @return {string}
 */
proto.pbs.PaperListReq.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PaperListReq} returns this
 */
proto.pbs.PaperListReq.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 page = 5;
 * @return {number}
 */
proto.pbs.PaperListReq.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PaperListReq} returns this
 */
proto.pbs.PaperListReq.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 page_size = 6;
 * @return {number}
 */
proto.pbs.PaperListReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PaperListReq} returns this
 */
proto.pbs.PaperListReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 question_type = 7;
 * @return {number}
 */
proto.pbs.PaperListReq.prototype.getQuestionType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PaperListReq} returns this
 */
proto.pbs.PaperListReq.prototype.setQuestionType = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.OperatePaperReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.OperatePaperReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.OperatePaperReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperatePaperReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timer: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    majorid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    facultyid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    schoolid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    questionlistkey: jspb.Message.getFieldWithDefault(msg, 7, ""),
    paperid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    scorestandardid: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.OperatePaperReq}
 */
proto.pbs.OperatePaperReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.OperatePaperReq;
  return proto.pbs.OperatePaperReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.OperatePaperReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.OperatePaperReq}
 */
proto.pbs.OperatePaperReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimer(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionlistkey(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaperid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setScorestandardid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.OperatePaperReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.OperatePaperReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.OperatePaperReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperatePaperReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimer();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getMajorid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFacultyid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSchoolid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getQuestionlistkey();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPaperid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getScorestandardid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.pbs.OperatePaperReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.OperatePaperReq} returns this
 */
proto.pbs.OperatePaperReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 timer = 2;
 * @return {number}
 */
proto.pbs.OperatePaperReq.prototype.getTimer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperatePaperReq} returns this
 */
proto.pbs.OperatePaperReq.prototype.setTimer = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool status = 3;
 * @return {boolean}
 */
proto.pbs.OperatePaperReq.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.OperatePaperReq} returns this
 */
proto.pbs.OperatePaperReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string majorId = 4;
 * @return {string}
 */
proto.pbs.OperatePaperReq.prototype.getMajorid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.OperatePaperReq} returns this
 */
proto.pbs.OperatePaperReq.prototype.setMajorid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string facultyId = 5;
 * @return {string}
 */
proto.pbs.OperatePaperReq.prototype.getFacultyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.OperatePaperReq} returns this
 */
proto.pbs.OperatePaperReq.prototype.setFacultyid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string schoolId = 6;
 * @return {string}
 */
proto.pbs.OperatePaperReq.prototype.getSchoolid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.OperatePaperReq} returns this
 */
proto.pbs.OperatePaperReq.prototype.setSchoolid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string questionListKey = 7;
 * @return {string}
 */
proto.pbs.OperatePaperReq.prototype.getQuestionlistkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.OperatePaperReq} returns this
 */
proto.pbs.OperatePaperReq.prototype.setQuestionlistkey = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string paperId = 8;
 * @return {string}
 */
proto.pbs.OperatePaperReq.prototype.getPaperid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.OperatePaperReq} returns this
 */
proto.pbs.OperatePaperReq.prototype.setPaperid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string scoreStandardId = 9;
 * @return {string}
 */
proto.pbs.OperatePaperReq.prototype.getScorestandardid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.OperatePaperReq} returns this
 */
proto.pbs.OperatePaperReq.prototype.setScorestandardid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CreatePaperForm.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CreatePaperForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CreatePaperForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CreatePaperForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timer: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    majorid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    questionlistkey: jspb.Message.getFieldWithDefault(msg, 5, ""),
    paperid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    scorestandardid: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CreatePaperForm}
 */
proto.pbs.CreatePaperForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CreatePaperForm;
  return proto.pbs.CreatePaperForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CreatePaperForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CreatePaperForm}
 */
proto.pbs.CreatePaperForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimer(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionlistkey(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaperid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setScorestandardid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CreatePaperForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CreatePaperForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CreatePaperForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CreatePaperForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimer();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getMajorid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQuestionlistkey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPaperid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getScorestandardid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.pbs.CreatePaperForm.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.CreatePaperForm} returns this
 */
proto.pbs.CreatePaperForm.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 timer = 2;
 * @return {number}
 */
proto.pbs.CreatePaperForm.prototype.getTimer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CreatePaperForm} returns this
 */
proto.pbs.CreatePaperForm.prototype.setTimer = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool status = 3;
 * @return {boolean}
 */
proto.pbs.CreatePaperForm.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.CreatePaperForm} returns this
 */
proto.pbs.CreatePaperForm.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string majorId = 4;
 * @return {string}
 */
proto.pbs.CreatePaperForm.prototype.getMajorid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.CreatePaperForm} returns this
 */
proto.pbs.CreatePaperForm.prototype.setMajorid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string questionListKey = 5;
 * @return {string}
 */
proto.pbs.CreatePaperForm.prototype.getQuestionlistkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.CreatePaperForm} returns this
 */
proto.pbs.CreatePaperForm.prototype.setQuestionlistkey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string paperId = 6;
 * @return {string}
 */
proto.pbs.CreatePaperForm.prototype.getPaperid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.CreatePaperForm} returns this
 */
proto.pbs.CreatePaperForm.prototype.setPaperid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string scoreStandardId = 7;
 * @return {string}
 */
proto.pbs.CreatePaperForm.prototype.getScorestandardid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.CreatePaperForm} returns this
 */
proto.pbs.CreatePaperForm.prototype.setScorestandardid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Scores.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Scores.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Scores} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Scores.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionlistkey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    selcount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    mselcount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    comcount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    markcount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    saqcount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    scores: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Scores}
 */
proto.pbs.Scores.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Scores;
  return proto.pbs.Scores.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Scores} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Scores}
 */
proto.pbs.Scores.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionlistkey(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelcount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMselcount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setComcount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMarkcount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSaqcount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setScores(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Scores.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Scores.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Scores} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Scores.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionlistkey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSelcount();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getMselcount();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getComcount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getMarkcount();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getSaqcount();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getScores();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string questionListKey = 1;
 * @return {string}
 */
proto.pbs.Scores.prototype.getQuestionlistkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Scores} returns this
 */
proto.pbs.Scores.prototype.setQuestionlistkey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 selCount = 5;
 * @return {number}
 */
proto.pbs.Scores.prototype.getSelcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Scores} returns this
 */
proto.pbs.Scores.prototype.setSelcount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 mselCount = 6;
 * @return {number}
 */
proto.pbs.Scores.prototype.getMselcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Scores} returns this
 */
proto.pbs.Scores.prototype.setMselcount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 comCount = 7;
 * @return {number}
 */
proto.pbs.Scores.prototype.getComcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Scores} returns this
 */
proto.pbs.Scores.prototype.setComcount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 markCount = 8;
 * @return {number}
 */
proto.pbs.Scores.prototype.getMarkcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Scores} returns this
 */
proto.pbs.Scores.prototype.setMarkcount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 saqCount = 9;
 * @return {number}
 */
proto.pbs.Scores.prototype.getSaqcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Scores} returns this
 */
proto.pbs.Scores.prototype.setSaqcount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 scores = 2;
 * @return {number}
 */
proto.pbs.Scores.prototype.getScores = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Scores} returns this
 */
proto.pbs.Scores.prototype.setScores = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.QuestionCount.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.QuestionCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.QuestionCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.QuestionCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    majorid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    selcount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    mselcount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    comcount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    markcount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    saqcount: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.QuestionCount}
 */
proto.pbs.QuestionCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.QuestionCount;
  return proto.pbs.QuestionCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.QuestionCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.QuestionCount}
 */
proto.pbs.QuestionCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelcount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMselcount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setComcount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMarkcount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSaqcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.QuestionCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.QuestionCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.QuestionCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.QuestionCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMajorid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSelcount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getMselcount();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getComcount();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getMarkcount();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getSaqcount();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional string majorId = 1;
 * @return {string}
 */
proto.pbs.QuestionCount.prototype.getMajorid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.QuestionCount} returns this
 */
proto.pbs.QuestionCount.prototype.setMajorid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 selCount = 2;
 * @return {number}
 */
proto.pbs.QuestionCount.prototype.getSelcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.QuestionCount} returns this
 */
proto.pbs.QuestionCount.prototype.setSelcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 mselCount = 3;
 * @return {number}
 */
proto.pbs.QuestionCount.prototype.getMselcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.QuestionCount} returns this
 */
proto.pbs.QuestionCount.prototype.setMselcount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 comCount = 4;
 * @return {number}
 */
proto.pbs.QuestionCount.prototype.getComcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.QuestionCount} returns this
 */
proto.pbs.QuestionCount.prototype.setComcount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 markCount = 5;
 * @return {number}
 */
proto.pbs.QuestionCount.prototype.getMarkcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.QuestionCount} returns this
 */
proto.pbs.QuestionCount.prototype.setMarkcount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 saqCount = 6;
 * @return {number}
 */
proto.pbs.QuestionCount.prototype.getSaqcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.QuestionCount} returns this
 */
proto.pbs.QuestionCount.prototype.setSaqcount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SearchPaper.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SearchPaper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SearchPaper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SearchPaper.toObject = function(includeInstance, msg) {
  var f, obj = {
    majorid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    keyword: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pagesize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    page: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SearchPaper}
 */
proto.pbs.SearchPaper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SearchPaper;
  return proto.pbs.SearchPaper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SearchPaper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SearchPaper}
 */
proto.pbs.SearchPaper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPagesize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SearchPaper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SearchPaper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SearchPaper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SearchPaper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMajorid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string majorId = 1;
 * @return {string}
 */
proto.pbs.SearchPaper.prototype.getMajorid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SearchPaper} returns this
 */
proto.pbs.SearchPaper.prototype.setMajorid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string keyword = 2;
 * @return {string}
 */
proto.pbs.SearchPaper.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SearchPaper} returns this
 */
proto.pbs.SearchPaper.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 pageSize = 3;
 * @return {number}
 */
proto.pbs.SearchPaper.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SearchPaper} returns this
 */
proto.pbs.SearchPaper.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 page = 4;
 * @return {number}
 */
proto.pbs.SearchPaper.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SearchPaper} returns this
 */
proto.pbs.SearchPaper.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.Questions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Questions.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Questions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Questions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Questions.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.pbs.Question.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Questions}
 */
proto.pbs.Questions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Questions;
  return proto.pbs.Questions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Questions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Questions}
 */
proto.pbs.Questions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.Question;
      reader.readMessage(value,proto.pbs.Question.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPagesize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Questions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Questions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Questions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Questions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.Question.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated Question data = 1;
 * @return {!Array<!proto.pbs.Question>}
 */
proto.pbs.Questions.prototype.getDataList = function() {
  return /** @type{!Array<!proto.pbs.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Question, 1));
};


/**
 * @param {!Array<!proto.pbs.Question>} value
 * @return {!proto.pbs.Questions} returns this
*/
proto.pbs.Questions.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Question}
 */
proto.pbs.Questions.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Questions} returns this
 */
proto.pbs.Questions.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.pbs.Questions.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Questions} returns this
 */
proto.pbs.Questions.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pageSize = 3;
 * @return {number}
 */
proto.pbs.Questions.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Questions} returns this
 */
proto.pbs.Questions.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.Papers.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Papers.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Papers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Papers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Papers.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.pbs.Paper.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Papers}
 */
proto.pbs.Papers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Papers;
  return proto.pbs.Papers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Papers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Papers}
 */
proto.pbs.Papers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.Paper;
      reader.readMessage(value,proto.pbs.Paper.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPagesize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Papers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Papers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Papers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Papers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.Paper.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated Paper data = 1;
 * @return {!Array<!proto.pbs.Paper>}
 */
proto.pbs.Papers.prototype.getDataList = function() {
  return /** @type{!Array<!proto.pbs.Paper>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Paper, 1));
};


/**
 * @param {!Array<!proto.pbs.Paper>} value
 * @return {!proto.pbs.Papers} returns this
*/
proto.pbs.Papers.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.Paper=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Paper}
 */
proto.pbs.Papers.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.Paper, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Papers} returns this
 */
proto.pbs.Papers.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.pbs.Papers.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Papers} returns this
 */
proto.pbs.Papers.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pageSize = 3;
 * @return {number}
 */
proto.pbs.Papers.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Papers} returns this
 */
proto.pbs.Papers.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.QuestionSearchForm.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.QuestionSearchForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.QuestionSearchForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.QuestionSearchForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    majorid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pagesize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    page: jspb.Message.getFieldWithDefault(msg, 4, 0),
    questionType: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.QuestionSearchForm}
 */
proto.pbs.QuestionSearchForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.QuestionSearchForm;
  return proto.pbs.QuestionSearchForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.QuestionSearchForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.QuestionSearchForm}
 */
proto.pbs.QuestionSearchForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPagesize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuestionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.QuestionSearchForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.QuestionSearchForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.QuestionSearchForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.QuestionSearchForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMajorid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getQuestionType();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string keyword = 1;
 * @return {string}
 */
proto.pbs.QuestionSearchForm.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.QuestionSearchForm} returns this
 */
proto.pbs.QuestionSearchForm.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string majorId = 2;
 * @return {string}
 */
proto.pbs.QuestionSearchForm.prototype.getMajorid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.QuestionSearchForm} returns this
 */
proto.pbs.QuestionSearchForm.prototype.setMajorid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 pageSize = 3;
 * @return {number}
 */
proto.pbs.QuestionSearchForm.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.QuestionSearchForm} returns this
 */
proto.pbs.QuestionSearchForm.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 page = 4;
 * @return {number}
 */
proto.pbs.QuestionSearchForm.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.QuestionSearchForm} returns this
 */
proto.pbs.QuestionSearchForm.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 question_type = 5;
 * @return {number}
 */
proto.pbs.QuestionSearchForm.prototype.getQuestionType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.QuestionSearchForm} returns this
 */
proto.pbs.QuestionSearchForm.prototype.setQuestionType = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.CraftOrderList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CraftOrderList.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CraftOrderList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CraftOrderList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CraftOrderList.toObject = function(includeInstance, msg) {
  var f, obj = {
    craftOrderList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CraftOrderList}
 */
proto.pbs.CraftOrderList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CraftOrderList;
  return proto.pbs.CraftOrderList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CraftOrderList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CraftOrderList}
 */
proto.pbs.CraftOrderList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setCraftOrderList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CraftOrderList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CraftOrderList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CraftOrderList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CraftOrderList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCraftOrderList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 craft_order = 1;
 * @return {!Array<number>}
 */
proto.pbs.CraftOrderList.prototype.getCraftOrderList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.pbs.CraftOrderList} returns this
 */
proto.pbs.CraftOrderList.prototype.setCraftOrderList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.pbs.CraftOrderList} returns this
 */
proto.pbs.CraftOrderList.prototype.addCraftOrder = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CraftOrderList} returns this
 */
proto.pbs.CraftOrderList.prototype.clearCraftOrderList = function() {
  return this.setCraftOrderList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.Question.repeatedFields_ = [4,20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Question.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Question.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Question} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Question.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    questionname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    questionmajor: (f = msg.getQuestionmajor()) && major_pb.Major.toObject(includeInstance, f),
    questiontype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    questionanswerList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    questionanswerdesc: jspb.Message.getFieldWithDefault(msg, 24, ""),
    questionexamanswerList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
    questionoptionMap: (f = msg.getQuestionoptionMap()) ? f.toObject(includeInstance, undefined) : [],
    questionmedia: jspb.Message.getFieldWithDefault(msg, 15, ""),
    questionscores: jspb.Message.getFieldWithDefault(msg, 5, 0),
    questionenable: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    questioncorrect: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    questionauthor: (f = msg.getQuestionauthor()) && user_pb.User.toObject(includeInstance, f),
    questiondeleteat: jspb.Message.getFieldWithDefault(msg, 18, 0),
    questionupdateat: jspb.Message.getFieldWithDefault(msg, 8, 0),
    questioncreateat: jspb.Message.getFieldWithDefault(msg, 9, 0),
    facultyId: jspb.Message.getFieldWithDefault(msg, 19, ""),
    schoolId: jspb.Message.getFieldWithDefault(msg, 21, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Question}
 */
proto.pbs.Question.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Question;
  return proto.pbs.Question.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Question} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Question}
 */
proto.pbs.Question.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionname(value);
      break;
    case 17:
      var value = new major_pb.Major;
      reader.readMessage(value,major_pb.Major.deserializeBinaryFromReader);
      msg.setQuestionmajor(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuestiontype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addQuestionanswer(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionanswerdesc(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.addQuestionexamanswer(value);
      break;
    case 14:
      var value = msg.getQuestionoptionMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionmedia(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuestionscores(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQuestionenable(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQuestioncorrect(value);
      break;
    case 7:
      var value = new user_pb.User;
      reader.readMessage(value,user_pb.User.deserializeBinaryFromReader);
      msg.setQuestionauthor(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuestiondeleteat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuestionupdateat(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuestioncreateat(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Question.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Question.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Question} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Question.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuestionname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestionmajor();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      major_pb.Major.serializeBinaryToWriter
    );
  }
  f = message.getQuestiontype();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getQuestionanswerList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getQuestionanswerdesc();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getQuestionexamanswerList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      20,
      f
    );
  }
  f = message.getQuestionoptionMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(14, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getQuestionmedia();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getQuestionscores();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getQuestionenable();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getQuestioncorrect();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getQuestionauthor();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getQuestiondeleteat();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getQuestionupdateat();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getQuestioncreateat();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
};


/**
 * optional string questionId = 1;
 * @return {string}
 */
proto.pbs.Question.prototype.getQuestionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.setQuestionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string questionName = 2;
 * @return {string}
 */
proto.pbs.Question.prototype.getQuestionname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.setQuestionname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Major questionMajor = 17;
 * @return {?proto.pbs.Major}
 */
proto.pbs.Question.prototype.getQuestionmajor = function() {
  return /** @type{?proto.pbs.Major} */ (
    jspb.Message.getWrapperField(this, major_pb.Major, 17));
};


/**
 * @param {?proto.pbs.Major|undefined} value
 * @return {!proto.pbs.Question} returns this
*/
proto.pbs.Question.prototype.setQuestionmajor = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.clearQuestionmajor = function() {
  return this.setQuestionmajor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Question.prototype.hasQuestionmajor = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional uint32 questionType = 3;
 * @return {number}
 */
proto.pbs.Question.prototype.getQuestiontype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.setQuestiontype = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated string questionAnswer = 4;
 * @return {!Array<string>}
 */
proto.pbs.Question.prototype.getQuestionanswerList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.setQuestionanswerList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.addQuestionanswer = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.clearQuestionanswerList = function() {
  return this.setQuestionanswerList([]);
};


/**
 * optional string questionAnswerDesc = 24;
 * @return {string}
 */
proto.pbs.Question.prototype.getQuestionanswerdesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.setQuestionanswerdesc = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * repeated string questionExamAnswer = 20;
 * @return {!Array<string>}
 */
proto.pbs.Question.prototype.getQuestionexamanswerList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.setQuestionexamanswerList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.addQuestionexamanswer = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.clearQuestionexamanswerList = function() {
  return this.setQuestionexamanswerList([]);
};


/**
 * map<string, string> questionOption = 14;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.pbs.Question.prototype.getQuestionoptionMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 14, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.clearQuestionoptionMap = function() {
  this.getQuestionoptionMap().clear();
  return this;};


/**
 * optional string questionMedia = 15;
 * @return {string}
 */
proto.pbs.Question.prototype.getQuestionmedia = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.setQuestionmedia = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional uint32 questionScores = 5;
 * @return {number}
 */
proto.pbs.Question.prototype.getQuestionscores = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.setQuestionscores = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool questionEnable = 6;
 * @return {boolean}
 */
proto.pbs.Question.prototype.getQuestionenable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.setQuestionenable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool questionCorrect = 16;
 * @return {boolean}
 */
proto.pbs.Question.prototype.getQuestioncorrect = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.setQuestioncorrect = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional User questionAuthor = 7;
 * @return {?proto.pbs.User}
 */
proto.pbs.Question.prototype.getQuestionauthor = function() {
  return /** @type{?proto.pbs.User} */ (
    jspb.Message.getWrapperField(this, user_pb.User, 7));
};


/**
 * @param {?proto.pbs.User|undefined} value
 * @return {!proto.pbs.Question} returns this
*/
proto.pbs.Question.prototype.setQuestionauthor = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.clearQuestionauthor = function() {
  return this.setQuestionauthor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Question.prototype.hasQuestionauthor = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 questionDeleteAt = 18;
 * @return {number}
 */
proto.pbs.Question.prototype.getQuestiondeleteat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.setQuestiondeleteat = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 questionUpdateAt = 8;
 * @return {number}
 */
proto.pbs.Question.prototype.getQuestionupdateat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.setQuestionupdateat = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 questionCreateAt = 9;
 * @return {number}
 */
proto.pbs.Question.prototype.getQuestioncreateat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.setQuestioncreateat = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string faculty_id = 19;
 * @return {string}
 */
proto.pbs.Question.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string school_id = 21;
 * @return {string}
 */
proto.pbs.Question.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Question} returns this
 */
proto.pbs.Question.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.Paper.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Paper.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Paper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Paper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Paper.toObject = function(includeInstance, msg) {
  var f, obj = {
    paperid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    papersn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    papername: jspb.Message.getFieldWithDefault(msg, 3, ""),
    paperdur: jspb.Message.getFieldWithDefault(msg, 4, 0),
    paperquestionList: jspb.Message.toObjectList(msg.getPaperquestionList(),
    proto.pbs.Question.toObject, includeInstance),
    paperenable: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    papermajor: (f = msg.getPapermajor()) && major_pb.Major.toObject(includeInstance, f),
    paperauthor: (f = msg.getPaperauthor()) && user_pb.User.toObject(includeInstance, f),
    paperscorestandard: (f = msg.getPaperscorestandard()) && score_standard_pb.ScoreStandard.toObject(includeInstance, f),
    paperdeleteat: jspb.Message.getFieldWithDefault(msg, 10, 0),
    paperupdateat: jspb.Message.getFieldWithDefault(msg, 11, 0),
    papercreateat: jspb.Message.getFieldWithDefault(msg, 12, 0),
    facultyId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    schoolId: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Paper}
 */
proto.pbs.Paper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Paper;
  return proto.pbs.Paper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Paper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Paper}
 */
proto.pbs.Paper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaperid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPapersn(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPapername(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaperdur(value);
      break;
    case 5:
      var value = new proto.pbs.Question;
      reader.readMessage(value,proto.pbs.Question.deserializeBinaryFromReader);
      msg.addPaperquestion(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaperenable(value);
      break;
    case 7:
      var value = new major_pb.Major;
      reader.readMessage(value,major_pb.Major.deserializeBinaryFromReader);
      msg.setPapermajor(value);
      break;
    case 8:
      var value = new user_pb.User;
      reader.readMessage(value,user_pb.User.deserializeBinaryFromReader);
      msg.setPaperauthor(value);
      break;
    case 9:
      var value = new score_standard_pb.ScoreStandard;
      reader.readMessage(value,score_standard_pb.ScoreStandard.deserializeBinaryFromReader);
      msg.setPaperscorestandard(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaperdeleteat(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaperupdateat(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPapercreateat(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Paper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Paper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Paper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Paper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaperid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPapersn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPapername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPaperdur();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPaperquestionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.pbs.Question.serializeBinaryToWriter
    );
  }
  f = message.getPaperenable();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPapermajor();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      major_pb.Major.serializeBinaryToWriter
    );
  }
  f = message.getPaperauthor();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getPaperscorestandard();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      score_standard_pb.ScoreStandard.serializeBinaryToWriter
    );
  }
  f = message.getPaperdeleteat();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getPaperupdateat();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getPapercreateat();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string paperId = 1;
 * @return {string}
 */
proto.pbs.Paper.prototype.getPaperid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Paper} returns this
 */
proto.pbs.Paper.prototype.setPaperid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string paperSn = 2;
 * @return {string}
 */
proto.pbs.Paper.prototype.getPapersn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Paper} returns this
 */
proto.pbs.Paper.prototype.setPapersn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string paperName = 3;
 * @return {string}
 */
proto.pbs.Paper.prototype.getPapername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Paper} returns this
 */
proto.pbs.Paper.prototype.setPapername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 paperDur = 4;
 * @return {number}
 */
proto.pbs.Paper.prototype.getPaperdur = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Paper} returns this
 */
proto.pbs.Paper.prototype.setPaperdur = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated Question paperQuestion = 5;
 * @return {!Array<!proto.pbs.Question>}
 */
proto.pbs.Paper.prototype.getPaperquestionList = function() {
  return /** @type{!Array<!proto.pbs.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Question, 5));
};


/**
 * @param {!Array<!proto.pbs.Question>} value
 * @return {!proto.pbs.Paper} returns this
*/
proto.pbs.Paper.prototype.setPaperquestionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.pbs.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Question}
 */
proto.pbs.Paper.prototype.addPaperquestion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pbs.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Paper} returns this
 */
proto.pbs.Paper.prototype.clearPaperquestionList = function() {
  return this.setPaperquestionList([]);
};


/**
 * optional bool paperEnable = 6;
 * @return {boolean}
 */
proto.pbs.Paper.prototype.getPaperenable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.Paper} returns this
 */
proto.pbs.Paper.prototype.setPaperenable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional Major paperMajor = 7;
 * @return {?proto.pbs.Major}
 */
proto.pbs.Paper.prototype.getPapermajor = function() {
  return /** @type{?proto.pbs.Major} */ (
    jspb.Message.getWrapperField(this, major_pb.Major, 7));
};


/**
 * @param {?proto.pbs.Major|undefined} value
 * @return {!proto.pbs.Paper} returns this
*/
proto.pbs.Paper.prototype.setPapermajor = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Paper} returns this
 */
proto.pbs.Paper.prototype.clearPapermajor = function() {
  return this.setPapermajor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Paper.prototype.hasPapermajor = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional User paperAuthor = 8;
 * @return {?proto.pbs.User}
 */
proto.pbs.Paper.prototype.getPaperauthor = function() {
  return /** @type{?proto.pbs.User} */ (
    jspb.Message.getWrapperField(this, user_pb.User, 8));
};


/**
 * @param {?proto.pbs.User|undefined} value
 * @return {!proto.pbs.Paper} returns this
*/
proto.pbs.Paper.prototype.setPaperauthor = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Paper} returns this
 */
proto.pbs.Paper.prototype.clearPaperauthor = function() {
  return this.setPaperauthor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Paper.prototype.hasPaperauthor = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ScoreStandard paperScoreStandard = 9;
 * @return {?proto.pbs.ScoreStandard}
 */
proto.pbs.Paper.prototype.getPaperscorestandard = function() {
  return /** @type{?proto.pbs.ScoreStandard} */ (
    jspb.Message.getWrapperField(this, score_standard_pb.ScoreStandard, 9));
};


/**
 * @param {?proto.pbs.ScoreStandard|undefined} value
 * @return {!proto.pbs.Paper} returns this
*/
proto.pbs.Paper.prototype.setPaperscorestandard = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Paper} returns this
 */
proto.pbs.Paper.prototype.clearPaperscorestandard = function() {
  return this.setPaperscorestandard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Paper.prototype.hasPaperscorestandard = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int64 paperDeleteAt = 10;
 * @return {number}
 */
proto.pbs.Paper.prototype.getPaperdeleteat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Paper} returns this
 */
proto.pbs.Paper.prototype.setPaperdeleteat = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 paperUpdateAt = 11;
 * @return {number}
 */
proto.pbs.Paper.prototype.getPaperupdateat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Paper} returns this
 */
proto.pbs.Paper.prototype.setPaperupdateat = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 paperCreateAt = 12;
 * @return {number}
 */
proto.pbs.Paper.prototype.getPapercreateat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Paper} returns this
 */
proto.pbs.Paper.prototype.setPapercreateat = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string faculty_id = 13;
 * @return {string}
 */
proto.pbs.Paper.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Paper} returns this
 */
proto.pbs.Paper.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string school_id = 14;
 * @return {string}
 */
proto.pbs.Paper.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Paper} returns this
 */
proto.pbs.Paper.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


goog.object.extend(exports, proto.pbs);
