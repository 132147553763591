import { Injectable } from '@angular/core';
import {Service} from './service';
import {UserServiceClient} from '../pbs/user_grpc_web_pb';
import {LoginUser,User,ChangePwdForm,UserForm,UserListReq,BatchImportReq} from '../pbs/user_pb';
import {Empty} from '../pbs/common_pb';
import { Error, Status } from 'grpc-web';

@Injectable({
  providedIn: 'root'
})

/**
 * @class UserService
 * @desc 用户RPC服务类 调取远程服务器方法
 * @author Chen Meiujun
 * @date 2019-09-12
 */
export class UserService extends Service {

  userService:UserServiceClient //用户rpc客户端

  constructor() {
	  super()
	  this.userService = new UserServiceClient(this.url,null,null);
  }


  /**
   * @name getVerifyCode
   * @desc 获取登录验证码
   * @param callback 成功回调函数
   * @param fail 失败回调函数
   * @author Chen Meijun
   * @date 2019-09-12
   * @return LoginUser
   */
  getVerifyCode(callback:Function=null,fail:Function=null) {
    this.request(this.userService,'getCode',new Empty(),callback,fail)
	}

	/**
	 * @name login
	 * @desc 用户登录
	 * @param username 用户名
	 * @param passwd 密码
	 * @param code 验证码
	 * @param callback 成功回调函数
     * @param fail 失败回调函数
	 * @author Chen Meijun
	 * @date 2019-09-12
	 * @return {User}
	 */
	login(username:string,passwd:string,code:string, success:Function,fail:Function=null) {
	  let user = new LoginUser()
	  user.setName(username)
	  user.setPwd(passwd)
	  user.setCode(code)
    this.request(this.userService,'adminLogin',user,(res:User)=>{
        this.saveUser(res) //本地保存用户
        ngEvents.trigger('login');//发送登录事件
        if (success != null) {
          success(res) //成功回调 返回用户对象
        }
      },fail);
	}


	/**
	 * @description 修改密码
	 * @author Megan
	 * @date 2019-09-15
	 * @param {string} pwd
	 * @param {string} oldPwd
	 * @param {Function} callback
	 * @param {Function} [fail=null]
	 * @memberof UserService
	 */
	changePwd(pwd:string,oldPwd:string,callback:Function,fail:Function=null) {
		let form = new ChangePwdForm();
		form.setPwd(pwd);
		form.setOld(oldPwd);
		this.userService.changePwd(
				form, //修改密码表单参数
				this.metadata,//修改表单必须验证数据
				(err: Error, res:Empty)=> { //不是所有响应结果都是User 结果看proto来
					if (!err && callback != null) {
						callback() //成功回调 不返回结果
					}

					if (err && fail != null) {
						fail(err.message) //失败回调
					}
				});

	}

	/**
	 * @description 验证旧密码
	 * @author Megan
	 * @date 2019-09-24
	 * @param {string} oldPwd
	 * @param {Function} callback
	 * @param {Function} [fail=null]
	 * @memberof UserService
	 */
	checkOldPwd(oldPwd:string,callback:Function,fail:Function=null) {
		let form = new ChangePwdForm();
		form.setOld(oldPwd);
		this.userService.checkOldPwd(
				form, //修改密码表单参数
				this.metadata,//修改表单必须验证数据
				(err: Error, res:Empty)=> { //不是所有响应结果都是User 结果看proto来
					if (!err && callback != null) {
						callback() //成功回调 不返回结果
					}

					if (err && fail != null) {
						fail(err.message) //失败回调
					}
				});
	}

	/**
	 * @description 修改用户信息
	 * @author Megan
	 * @date 2019-09-24
	 * @param {string} param
	 * @param {Function} callback
	 * @param {Function} [fail=null]
	 * @memberof UserService
	 */
	edit(param: string,callback:Function,fail:Function=null) {
		let parm = JSON.parse(param);
		parm.userbirthday = parseInt((new Date(parm.userbirthday).valueOf() / 1000).toString());
		let userFrom = new UserForm();
		userFrom.setFname(parm.userfname);
		userFrom.setGender(parm.usergender);
		userFrom.setIdcard(parm.useridcard);
		userFrom.setNation(parm.usernation);
		userFrom.setDegree(parm.userdegree);
		userFrom.setAvatar(parm.useravatar);
		userFrom.setBirthday(parm.userbirthday);
		userFrom.setMobile(parm.usermobile);
		this.userService.edit(
			userFrom,
			this.metadata,
			(err: Error, res:User)=> {
				if (!err && callback != null) {
					let user = res.toObject();
					// let user = this.getUser();
					// user.userfname = parm.userfname;
					// user.usergender = parm.usergender;
					// user.useridcard = parm.useridcard;
					// user.usernation = parm.usernation;
					// user.userdegree = parm.userdegree;
					// user.useravatar = parm.useravatar;
					// user.userbirthday = parm.userbirthday;
					// user.usermobile = parm.usermobile;
					this.removeUser();
					this.saveUser(user);
					callback() //成功回调 不返回结果
				}

				if (err && fail != null) {
					fail(err.message) //失败回调
				}
			});
	}

  /**
   * @description 获取用户(学生、老师、管理员)列表
   * @author Megan
   * @date 2019-09-15
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof UserService
   */
  userList(req:UserListReq,callback:Function=null,fail:Function=null) {
    this.request(this.userService,'userList',req,callback,fail)
  }

  createUser(req:User,callback:Function=null,fail:Function=null) {
    this.request(this.userService,'createUser',req,callback,fail)
  }

  delete(req:User,callback:Function=null,fail:Function=null) {
    this.request(this.userService,'delete',req,callback,fail)
  }

  newCreateUser(req:User,callback:Function=null,fail:Function=null) {
    this.request(this.userService,'newCreateUser',req,callback,fail)
  }

  editUser(req:User,callback:Function=null,fail:Function=null) {
    this.request(this.userService,'editUser',req,callback,fail)
  }

  batchImport(req:BatchImportReq,callback:Function=null,fail:Function=null) {
    this.request(this.userService,'batchImport',req,callback,fail)
  }

  getClientLoginNum(callback:Function=null,fail:Function=null) {
    this.request(this.userService,'getClientLoginNum',new Empty(),callback,fail)
  }
}
