import { Component, OnInit } from '@angular/core';

// Dialog
@Component({
  selector: 'dialog-accept',
  templateUrl: './accept.component.html',
  styleUrls: ['./accept.component.css'],
})
export class DialogAcceptComponent {
  public title:string = '确定删除吗？'
  public content:string = '您确定要删除这条记录吗?'
}
