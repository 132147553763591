import { Component,Input,Output,SimpleChanges } from '@angular/core';
import { User } from '@core/pbs/user_pb'
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-user',
  template: `
    <a mat-button href="javascript:void(0)" [matMenuTriggerFor]="menu">
      <img
        class="matero-user-avatar r-full align-middle"
        src="{{avatar}}"
        width="24"
        alt="avatar"
      />
      <span class="align-middle">{{fname}}</span>
    </a>
    <mat-menu #menu="matMenu">
      <a routerLink="/profile/overview" mat-menu-item>
        <mat-icon>account_circle</mat-icon>
        <span>个人信息</span>
      </a>
      <a routerLink="/profile/settings" mat-menu-item>
        <mat-icon>settings</mat-icon>
        <span>信息设置</span>
      </a>
      <a (click)="logout()" mat-menu-item>
        <mat-icon>exit_to_app</mat-icon>
        <span>管理登出</span>
      </a>
    </mat-menu>
  `,
})

export class UserComponent {

  avatar = '';
  fname = '';
  @Input() profile:any;

	constructor(private router: Router) {}

	ngOnChanges() {
     if (this.profile != null) {
       this.avatar = environment.domainName + this.profile.useravatar;
       this.fname = this.profile.userfname;
     }
  }

  logout() {
     ngEvents.trigger('logout');
  }
}
