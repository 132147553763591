import { Component, OnInit,Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig,FormlyFormOptions } from '@ngx-formly/core';

import { ToastrService } from 'ngx-toastr';

import { PaperService } from '@core/services/paper.service';
import { SearchPaper,Questions } from '@core/pbs/paper_pb';
import {Major} from '@core/pbs/major_pb';

@Component({
  selector: 'comm-from',
  templateUrl: './form.component.html',
  providers: [],
})
export class DialogFormComponent implements OnInit {

  @Output() dataChange = (e,o)=>{};
  @Output() submit = ($this)=>{};
  form = new FormGroup({});
  title = '添加'
  header = '';
  model:any = new Object();
  fields: FormlyFieldConfig[] = [];
  options:FormlyFormOptions = {}

  constructor(private toastr: ToastrService,private pservice:PaperService) {}

  ngOnInit() {}


  showToast() {
    this.toastr.success(JSON.stringify(this.model));
  }


  listPaperByMajor(majorid:string,callback:Function) {
    let param = new SearchPaper()
    param.setMajorid(majorid)
    this.pservice.search(param,callback,(err:string)=>{});
  }


  listQuestionByMajor(mid:string,form) {
    let major = new Major()
    major.setMajorid(mid)
    return new Promise((resolve,reject)=>{
      this.pservice.listQuestionByMajor(major,(data:Questions)=>{
          this['questionList'] = data.getDataList().map((v)=>{
            let item = v.toObject(true)
            item.value = item.questionid
            item.label = item.questionname.replace(/\@\[input\]/g,'__')+'('+item.questionscores+'分)';
            //题目类型 0=单选题 1=多选 2=填空题 3=标注题 4=简答题 5=实操题
            let types = ['单选题','多选题','填空题','标注题','简答题','实操题'];

            item.group = '未知';

            if (item.questiontype >= 0 && item.questiontype<=5) {
              item.group = types[item.questiontype];
            }
            return item
          })
          this.fields[this.fields.length-1].templateOptions.options = this['questionList']
          resolve(this['questionList'])
        },(err:string)=>{
          this.toastr.error(err,'获取专业数据失败');
          reject(err)
        });
        //结束请求
      });
  }
}
