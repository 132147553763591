import { Component, Input } from '@angular/core';
import { MenuService } from '@core';
import { Service } from '@core/services/service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
})
export class SidemenuComponent {
  // NOTE: Ripple effect make page flashing on mobile
  @Input() ripple = true;

  menus = this.menuService.getAll();
  user = this.service.getUser().toObject();

  constructor(private menuService: MenuService,private service: Service) {
    console.log("users", this.user)
  }

  // Delete empty value in array
  filterStates(states: string[]) {
    return states.filter(item => item && item.trim());
  }
}
